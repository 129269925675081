.page-blog {
  #main {
    @extend .container;
  }
  h1.title {
    margin-top: 70px;
  }
}
.node-type-article {
  #page-header {
    background: #fff;
    padding: 40px 25px 0 25px;
    @extend .clearfix;
  }
  h1.title {
    color: #333;
    font-size: 30px;
    font-weight: 300;
    text-align: left;
    text-transform: none;
  }
}
.node-article-full {
  background: #fff;
  margin-bottom: 53px;
  padding: 0 25px 45px 25px;
  img {
    height: auto;
    max-width: 100%;
  }
  .submitted-container {
    margin-bottom: 23px;
    .comments-count {
      color: $blue;
    }
  }
  .field-name-field-article-preview {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .field-name-body {
    @extend .formatter-text;
    color: $grey;
    font-size: 14px;
  }
  @include respond-to('large') {
    .field-name-field-article-preview.field-type-image{
      float: left;
    }
  }
  .field-name-body.field-type-text-with-summary{
    .field-item{
      &>div>div{
        display: inline;
      }
    }
  }
}
.node-article-teaser {
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  height: 410px;
  padding: 21px 18px 25px 18px;
  width: 320px;
  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    .node-title {
      color: $yellow;
    }
    .article-category-icon {
      background-color: $yellow;
    }
    .comments-count {
      color: $yellow;
    }
  }
  .node-title {
    color: $black;
    display: block;
    font-size: 20px;
    height: 48px;
    line-height: 26px;
    overflow: hidden;
    text-decoration: none;
  }
  .field-name-field-article-preview {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  .field-name-body {
    font-size: 14px;
    height: 80px;
    line-height: 20px;
    margin-top: 20px;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
  }
  .bottom {
    margin-top: 10px;
  }
  .article-category-icon {
    background-color: $blue;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
    height: 30px;
    margin-right: 15px;
    width: 30px;
  }
  .submitted-container {
    display: inline-block;
    float: left;
    font-size: 13px;
    line-height: 30px;
  }
  .comments-count {
    color: $blue;
  }
}
.view-id-blog {
  padding-bottom: 100px;
  .view-filters {
    padding: 30px 0;
  }
  .views-exposed-widget {
    padding: 0;
  }
  .bef-select-as-links {
    letter-spacing: -10px;
    text-align: center;
  }
  .form-type-bef-link {
    display: inline-block;
    letter-spacing: normal;
    width: 170px;
    &.form-item-edit-field-article-category-tid-all {
      display: none;
    }
    a {
      border: 1px solid #e1e1e1;
      color: $grey;
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
      height: 40px;
      line-height: 40px;
      text-align: left;
      text-decoration: none;
      width: 100%;
      .article-category-icon {
        background-color: $blue;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        float: left;
        height: 30px;
        margin: 4px 15px;
        width: 30px;
      }
      &.active {
        .article-category-icon {
          background-color: $yellow;
        }
      }
    }
  }
  .view-content {
    display: inline-block;
    width: 100%;
  }
}
.article-comments-wrapper {
  background: #fff;
  padding: 60px 0;
}

@include respond-to('small') {
  .node-type-article {
    #page-header {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .node-article-full {
    padding-left: 10px;
    padding-right: 10px;
  }
  .view-id-blog {
    .views-row {
      padding-bottom: 40px;
      .node-article-teaser {
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
        width: 300px;
      }
    }
  }
}

@include respond-to('medium') {
  .view-id-blog {
    .views-row {
      padding-bottom: 40px;
      &.views-row-1,
      &.views-row-3,
      &.views-row-5,
      &.views-row-7,
      &.views-row-9,
      &.views-row-11 {
        padding-right: 16px;
        .node-article-teaser {
          float: right;
        }
      }
      &.views-row-2,
      &.views-row-4,
      &.views-row-6,
      &.views-row-8,
      &.views-row-10,
      &.views-row-12 {
        padding-left: 16px;
        .node-article-teaser {
          float: left;
        }
      }
    }
  }
}

@include respond-to('large') {
  .view-id-blog {
    .views-row {
      padding-bottom: 40px;
      &.views-row-2,
      &.views-row-5,
      &.views-row-8,
      &.views-row-11 {
        .node-article-teaser {
          margin: 0 auto;
        }
      }
      &.views-row-3,
      &.views-row-6,
      &.views-row-9,
      &.views-row-12 {
        .node-article-teaser {
          float: right;
        }
      }
    }
  }
}


