body.page-library{
  .pane-custom.page-title{
    text-align: center;
    text-transform: uppercase;
    h2{
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 900;
      color: #333333;
      font-size: 28px;
      margin: 75px 0 26px 0;
      span{
        color: #0e6fad;
      }
    }
    .after-title{
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 300;
      color: #333333;
      font-size: 15px;
      padding: 0 95px;
      line-height: 23px;
    }
  }
}

.view-v-reviews{
  margin: 46px 0 0 0;
  padding: 0 0 108px 0;
  .view-filters{
    .views-exposed-form, .views-exposed-widget{
      float: none;
      padding: 0;
    }
  }
  .view-content{
    padding: 37px 0 0 0;
    font-size: 0;
    .views-row{
      /*float: left;*/
      display: inline-table;
      width: 282px;
      min-height: 435px;
      margin-right: 89px;
      position: relative;
      margin-bottom: 49px;
      vertical-align: top;
      &:nth-child(3n+3){
        margin-right: 0%;
      }
      &:hover{
        .views-field-view-node{
          display: block;
        }
        .views-field-field-r-image{
          -moz-box-shadow: 0 8px 12px rgba(0,0,0,.1);
          -webkit-box-shadow: 0 8px 12px rgba(0,0,0,.1);
          box-shadow: 0 8px 12px rgba(0,0,0,.1);
        }
        .views-field-field-r-author-book{
          color: #f58220;
        }
      }
      .views-field-field-r-image{
        border:1px solid #e1e1e1;
        height: 269px;
        overflow: hidden;
        width: 100%;
        img {
          display: block;
          height: 269px;
          margin: auto;
          max-height: 100%;
          max-width: 100%;
          width: auto;
        }
      }
      .views-field-title{
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 300;
        color: #333333;
        font-size: 20px;
        margin: 22px 0 0 0;
      }
      .views-field-field-r-author-book{
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 300;
        color: #0e6fad;
        font-size: 18px;
        margin: 5px 0 0 0;
      }
      .views-field-body{
        color: #7a7a7a;
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        margin: 30px 0 0 0;
      }
      .views-field-view-node{
        display: none;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 260px;
        height: 249px;
        background-color: rgba(245, 129, 31, .95);
        background-image: url('/sites/all/themes/uworld/assets/images/open-magazine.png');
        background-repeat: no-repeat;
        background-position: top 60px center;
        a{
          color: #fff;
          font-family: "HelveticaNeueCyr";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          display: block;
          width: 188px;
          height: 40px;
          border: 1px solid #fff;
          text-align: center;
          line-height: 40px;
          margin: 0 auto;
          margin: 140px auto 0 auto;
        }
      }
    }
  }
  .item-list{
    clear: both;
    ul.pagination{
      margin: 4px 0 0 0;
    }
  }
}

@include respond-to('medium') {

  body.page-library{
    .pane-custom.page-title{
      h2{
        margin: 61px 0 26px 0;
      }
      .after-title{
        padding: 0px;
      }
    }
  }


  .view-v-reviews{
    margin: 28px 0 0 0;
    padding: 0 0 80px 0;
    .view-content{
      padding: 42px 0 0 0;
      .views-row{
        width: 215px;
        margin-right: 41px;
        .views-field-field-r-image{
          img{
            height: 204px;
          }
        }
        .views-field-view-node{
          width: 193px;
          height: 184px;
          background-position: top 40px center;
          a{
            width: 148px;
            margin: 120px auto 0 auto;
          }
        }
      }
    }
  }

}

@include respond-to('small') {

  body.page-library{
    .pane-custom.page-title{
      padding: 0 10px;
      h2{
        margin: 61px 0 26px 0;
      }
      .after-title{
        padding: 0px;
      }
    }
  }


  .view-v-reviews{
    margin: 28px 0 0 0;
    padding: 0 10px 80px 10px;
    .view-content{
      padding: 42px 0 0 0;
      .views-row{
        width: 215px;
        margin-right: auto;
        margin-left: auto;
        display: block;
        float: none;
        &:nth-child(3n+3){
          margin-right: auto;
        }
        .views-field-field-r-image{
          height: 204px;
          img{
            height: 204px;
          }
        }
        .views-field-view-node{
          width: 193px;
          height: 184px;
          background-position: top 40px center;
          a{
            width: 148px;
            margin: 120px auto 0 auto;
          }
        }
      }
    }
  }

}

/*Library inner*/
.node-type-m-library{
  #panel-bootstrap-region-center{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 64px;
    padding-bottom: 53px;
    background-color: #fff;
  }
  #panel-bootstrap-region-bottom_side{
    padding: 0px 0 55px 0;
    margin: 46px 0 0 0;
    background-color: #fff;
  }
  .pane-node-field-r-image{
    border: 1px solid #e1e1e1;
    float: left;
    width: 324px;
    margin: 0 26px 0 0;
  }

  .pane-node-title{
    margin: -5px 0 0 0;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    color: #333333;
    h2{
      margin: 0 0 0 0;
        font-weight: 300;
        line-height: 35px;
      &:before{
        content: 'Рецензия на книгу «';
      }
      &:after{
        content: '»';
      }
    }
  }

  .pane-node-field-r-author-book{
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #0e6fad;
    margin: 8px 0 14px 0;
  }

  .pane-node-body{
    color: #7a7a7a;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    p{
      margin: 0 0 19px;
      line-height: 20px;
    }
    em{
      font-family: "Arial";
      font-style: italic;
      color: #a0a0a0;
      line-height: 20px;
    }
    blockquote{
      background: #fafafc url(../images/quote_icon.png) left center no-repeat;
        border: none;
        padding: 30px 0 30px 45px;
        margin: 46px 0 12px 0;
        position: relative;
        > *{
          background-color: #eef4f7;
          border-left: 2px solid #f58220;
          display: block;
          padding: 10px 20px;
        }
        &:before{
          background: #fafafc;
          content: '';
          height: 100%;
          left: -25px;
          position: absolute;
          top: 0;
          width: 25px;
        }
        &:after{
          background: #fafafc;
            content: '';
            height: 100%;
            position: absolute;
            right: -25px;
            top: 0;
            width: 25px;
        }
    }
  }

  .pane-node-field-r-author-reviews{
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #a0a0a0;
    padding: 4px 0 0 0;
    .pane-title{
      float: left;
    }
    .pane-content{
      float: left;
      color: #0e6fad;
      padding: 0px 0 0 3px;
    }
  }

}

@include respond-to('medium') {

  .view-v-reviews {
    .view-content {
      .views-row {
        .views-field-field-r-image {
          height: 204px;
          img {
            height: 204px;
          }
        }
      }
    }
  }
  .node-type-m-library{
    #panel-bootstrap-region-center{
      padding-top: 67px;
    }
    #panel-bootstrap-region-bottom_side{
      background: none;
      margin: 0px 0 0 0;
    }

    .pane-node-field-r-image{

    }
    .pane-node-title{
      h2{
        line-height: 34px;
      }
    }
    .pane-node-field-r-author-book{

    }
    .pane-node-body{
      p{
        margin: 0 0 23px;
      }
      em{

      }
      blockquote{
        background: #fafafc url(../images/quote_icon.png) left top 43px no-repeat;
        border: none;
        padding: 26px 0 26px 45px;
        margin: 17px 0 17px 0;
        > *{
          padding: 10px 20px 23px 20px;
          margin-bottom: 0px;
        }
      }
    }
    .pane-node-field-r-author-reviews{
      .pane-title{

      }
      .pane-content{

      }
    }
  }

}

@include respond-to('small') {

  .node-type-m-library{
    #panel-bootstrap-region-center{
      padding-top: 45px;
      padding-left: 10px;
      padding-right: 10px;
    }
    #panel-bootstrap-region-bottom_side{
      background: none;
      margin: 0px 0 0 0;
    }

    .pane-node-field-r-image{
      float: none;
      width: 299px;
      height: 288px;
      margin: 0 auto 28px auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .pane-node-title{
      h2{
        line-height: 29px;
        font-size: 24px;
      }
    }
    .pane-node-field-r-author-book{

    }
    .pane-node-body{
      p{
        margin: 0 0 23px;
      }
      em{

      }
      blockquote{
        background: #fafafc url(../images/quote_icon.png) left top 43px no-repeat;
        border: none;
        padding: 26px 0 26px 45px;
        margin: 17px 0 17px 0;
        > *{
          padding: 10px 20px 23px 20px;
          margin-bottom: 0px;
        }
      }
    }
    .pane-node-field-r-author-reviews{
      .pane-title{

      }
      .pane-content{

      }
    }
  }

}


