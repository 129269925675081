body.page-price{

  #panel-bootstrap-region-center{

  }
  #panel-bootstrap-region-bottom_side{
    margin: 39px 0 0px 0;
    padding: 0 0 27px 0;
    background-color: #fff;
    .pane-webform{
      padding: 0;
      .form-control{
        background-color: #f2f6f8;
      }
      h2.pane-title{
        margin-top: 64px;
      }
    }
  }
  .pane-custom.page-title{
    text-align: center;
    h2{
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 900;
      color: #333333;
      font-size: 28px;
      margin: 92px 0 19px 0;
      text-transform: uppercase;
      span{
        color: #0e6fad;
      }
    }
    .after-title{
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 300;
      color: #333333;
      font-size: 15px;
      padding: 0 95px;
      line-height: 23px;
    }
  }

}

.view-id-v_price{
  padding: 28px 0 0 0;
  .views-exposed-widget {
    padding: 0;
  }
  .view-filters{
    .bef-select-as-checkboxes{
      .form-item{
        label{
          padding: 0 29px 0 48px;
        }
        &:nth-child(1){
          label{
            padding: 0 29px 0 47px;
          }
        }
        &:nth-child(2){
          label{
            padding: 0 29px 0 47px;
          }
        }
        &:nth-child(3){
          label{
            padding: 0 29px 0 47px;
          }
        }
      }
    }
  }

  .view-content{
    padding: 37px 0 0 0;
    h3{
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 300;
      color: #333333;
      font-size: 20px;
      text-align: center;
      margin: 0 0 16px 0;
    }
    .views-row{
      font-size: 0;
      clear: both;
      position: relative;
      z-index: 1;
      border-top: 1px solid #e1e1e1;
      padding: 7px 0 7px 0;
      &.views-row-last{
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 24px;
      }
      &:hover{
        transform: scale(1.05, 1.1);
        z-index: 2;
        background: #fff;
        -moz-box-shadow: 0 6px 10px rgba(0,0,0,.2);
        -webkit-box-shadow: 0 6px 10px rgba(0,0,0,.2);
        box-shadow: 0 6px 10px rgba(0,0,0,.2);
        border-top: none;
        padding-right: 23px;
        padding-left: 23px;
        .views-field-field-pp-name-service,
        .views-field-field-pp-price-service{
          color: #f58220
        }
        .views-field-field-pp-name-service{
          text-decoration: underline;
        }
      }
      .views-field-field-pp-name-service{
        color: #7a7a7a;
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        display: inline-table;
        width: 82%;
        font-size: 16px;
        line-height: 24px;
        color: #555555;
      }
      .views-field-field-pp-price-service{
        font-family: "HelveticaNeueCyr";
        font-style: normal;
          font-weight: 900;
          color: #333333;
          font-size: 18px;
        display: inline-table;
        width: 18%;
        text-align: right;
      }
    }

    div.table-responsive{
      table{
        border: none;
        caption{
          font-family: "HelveticaNeueCyr";
          font-style: normal;
          font-weight: 300;
          color: #333333;
          font-size: 20px;
          text-align: center;
        }
        tr{
          background: none;
          position: relative;
          z-index: 1;
          &:hover{
            transform: scale(1.1, 1.1);
            z-index: 2;
            background: #fff;
            -moz-box-shadow: 0 6px 10px rgba(0,0,0,.2);
            -webkit-box-shadow: 0 6px 10px rgba(0,0,0,.2);
            box-shadow: 0 6px 10px rgba(0,0,0,.2);
            .views-field-field-pp-name-service,
            .views-field-field-pp-price-service{
              color: #f58220
            }
          }
          td{
            border: none;
          }
          .views-field-field-pp-name-service{
            color: #7a7a7a;
            font-family: "HelveticaNeueCyr";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
          }
          .views-field-field-pp-price-service{
            font-family: "HelveticaNeueCyr";
            font-style: normal;
              font-weight: 900;
              color: #333333;
              font-size: 18px;
          }
        }
      }
    }

  }

}

@include respond-to('small') {
  body.page-price {
    .pane-custom.page-title {
      .after-title {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    #panel-bootstrap-region-bottom_side {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .view-id-v_price {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    .view-content {
      padding-left: 10px;
      padding-right: 10px;
       .views-row {
        .views-field-field-pp-name-service {
          width: 50%;
        }
        .views-field-field-pp-price-service {
          width: 50%;
        }
      }
    }
  }
}

@include respond-to('medium') {
  .view-id-v_price {
    .view-content {
      padding-left: 20px;
      padding-right: 20px;
       .views-row {
        .views-field-field-pp-name-service {
          width: 77%;
        }
        .views-field-field-pp-price-service {
          width: 23%;
        }
      }
    }
  }
}

