.node-type-contacts {
  h1.title {
    margin-bottom: 30px;
    margin-top: 70px;
  }
}
.node-contacts {
  .webform-client-form > div {
    @extend .container;
  }
  .field-name-body {
    @extend .container;
    padding: 0 120px 60px 150px;
    .contacts-col {
      padding: 0 10px;
      .contacts-icon {
        display: inline-block;
        float: left;
        width: 93px;
      }
      .contacts-info {
        color: #7a7a7a;
        display: inline-block;
        font-size: 14px;
        float: left;
        width: calc(100% - 93px);
      }
    }
    .row-links {
      margin-top: 40px;
      text-align: center;
      a {
        background-color: $yellow;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        height: 31px;
        line-height: 31px;
        text-decoration: none;
        text-align: center;
        width: 250px;
      }
    }
  }
  .webform-client-form {
    background: #fff;
    padding: 60px 0 30px 0;
    input[type=text],
    input[type=email],
    textarea {
      background-color: #f2f6f8;
      border-radius: 0;
      border: none;
      box-shadow: none;
      color: #333;
      font-size: 15px;
      font-weight: 300;
      padding: 0 23px;
    }
    input[type=text],
    input[type=email] {
      height: 40px;
    }
    textarea {
      height: 111px;
      padding-top: 10px;
      resize: none;
    }
    input[type=submit] {
      background-color: #0e6fad;
      border-radius: 0;
      color: #fff;
      display: inline-block;
      font-size: 18px;
      font-weight: 300;
      height: 35px;
      max-width: 379px;
      width: 100%;
    }
    .form-actions {
      display: inline-block;
      margin: 30px 0;
      text-align: center;
      width: 100%;
    }
    .form-item {
      margin: 10px 0;
      &.webform-component--imya {
        padding-right: 10px;
      }
      &.webform-component--e-mail {
        padding-left: 10px;
      }
    }
  }
}

@include respond-to('small') {
  .node-contacts {
    .field-name-body {
      padding: 0 0 40px 0;
      .contacts-col {
        margin-bottom: 40px;
      }
      .row-links {
        display: none;
      }
    }
    .webform-client-form {
      padding: 35px 10px 10px 10px;
      .form-item {
        &.webform-component--imya {
          padding: 0;
        }
        &.webform-component--e-mail {
          padding: 0;
        }
      }
    }
  }
}

@include respond-to('medium') {
  .node-contacts {
    .field-name-body {
      padding: 0 10px 50px 10px;
    }
    .webform-client-form {
      padding: 35px 0 10px 0;
    }
  }
}

@include respond-to('large') {
  .node-contacts {
    .field-name-body {
      .contacts-col {
        &:nth-child(2) {
          padding-left: 30px;
        }
      }
    }
  }
}










