.services-top-pane {
  background: url(../images/services_top_bg.jpg) center no-repeat;
  background-size: cover;
  height: 523px;
  padding-top: 110px;
  .pane-content {
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    h1 {
      font-weight: 300;
      font-size: 35px;
      margin-bottom: 20px;
    }
    .orange-container {
      display: inline-block;
      height: 160px;
      padding: 10px;
      left: -10px;
      position: relative;
      width: 160px;
      .link-block {
        background-color: rgba(245,130,32,.8);
        color: #fff;
        display: block;
        font-size: 15px;
        font-weight: 300;
        height: 140px;
        line-height: 18px;
        left: 10px;
        padding: 18px;
        position: absolute;
        top: 10px;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease;
        width: 140px;
        &:hover {
          background-color: $yellow;
          left: 5px;
          height: 150px;
          padding: 23px;
          top: 5px;
          width: 150px;
        }
      }
      .image-container {
        display: inline-block;
        height: 36px;
        margin-bottom: 10px;
        vertical-align: middle;
      }
    }
  }
}

.view-id-services {
  padding-top: 100px;
  .views-field-view {
    margin-bottom: 34px;
  }
  .views-field-field-section-image {
    a {
      background: #fff;
      border: 1px solid #e1e1e1;
      display: inline-block;
      padding: 15px;
    }
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  .section-title-link {
    margin-top: -47px;
    a {
      color: $black;
      display: block;
      font-size: 30px;
      font-weight: 900;
      line-height: 36px;
      padding: 0 56px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
  .block__content {
    font-size: 30px;
    color: $black;
    padding-bottom: 120px;
    .rectangle {
      background: url(../images/rectangle_services.png) right 33px center no-repeat;
      font-size: 55px;
      font-weight: 900;
      height: 207px;
      line-height: 65px;
      margin: 0 0 20px 0;
      padding: 42px 0 0 0;
    }
  }
}

.view-id-section_services_list {
  padding: 20px 0;
  .view-content {
    &:hover {
      a {
        &:before {
          display: none !important;
        }
      }
    }
  }
  .views-row {
    a {
      color: #7a7a7a;
      display: block;
      font-size: 18px;
      font-weight: 300;
      line-height: 36px;
      position: relative;
      text-decoration: none;
      &:before {
        background-color: #0e6fad;
        content: '';
        display: none;
        height: 2px;
        left: 0;
        position: absolute;
        top: 15px;
      }
      &:hover {
        color: #0e6fad;
        &:before {
          display: block !important;
        }
      }
    }
    &.views-row-1 {
      a {
        &:before {
          background-color: #7a7a7a;
          display: block;
        }
        &:hover {
          color: #0e6fad;
          &:before {
            background-color: #0e6fad;
            display: block !important;
          }
        }
      }
    }
  }
}


@include respond-to('small') {
  .services-top-pane {
    height: 852px;
    .container {
      padding-left: 10px;
      padding-right: 10px;
    }
    .pane-content {
      .orange-container {
        left: 0;
      }
    }
  }
  .view-id-services {
    > .view-content {
      padding-left: 10px;
      padding-right: 10px;
    }
    .section-title-link {
      margin-top: 20px;
      a {
        font-size: 21px;
        line-height: 26px;
        padding: 0;
      }
    }
    .block__content {
      font-size: 20px;
      font-weight: 300;
      line-height: 26px;
      padding-bottom: 60px;
      .rectangle {
        background: url(../images/rectangle_services_mobile.png) right 34px center no-repeat;
        font-size: 36px;
        height: 152px;
        line-height: 44px;
        padding-top: 33px;
      }
    }
  }
  .view-id-section_services_list {
    .views-row {
      a {
        padding-left: 0px;
        &:before {
          display: none;
        }
      }
    }
  }
}

@include respond-to('medium') {
  .services-top-pane {
    .pane-content {
      .orange-container {
        height: 140px;
        left: -5px;
        padding: 6px;
        width: 140px;
        .link-block {
          height: 130px;
          padding: 18px 14px;
          width: 130px;
          &:hover {
            left: 7px;
            height: 136px;
            padding: 21px 17px;
            top: 7px;
            width: 136px;
          }
        }
      }
    }
  }
  .view-id-services {
    .section-title-link {
      margin-top: -40px;
      a {
        font-size: 21px;
        line-height: 26px;
        padding: 0 43px;
      }
    }
    .block__content {
      font-size: 20px;
      font-weight: 300;
      line-height: 26px;
      padding-bottom: 60px;
      .rectangle {
        background: url(../images/rectangle_services_mobile.png) right 34px center no-repeat;
        font-size: 36px;
        height: 152px;
        line-height: 44px;
        padding-top: 33px;
      }
    }
  }
  .view-id-section_services_list {
    .views-row {
      a {
        padding-left: 43px;
        &:before {
          width: 23px;
        }
      }
    }
  }
}

@include respond-to('large') {
  .view-id-section_services_list {
    .view-content {
      padding-left: 56px;
    }
    .views-row {
      a {
        padding-left: 80px;
        &:before {
          width: 58px;
        }
      }
    }
  }
}

