.page-webform-thank-you {
  .pane-custom {
    &.pane-1 {
      background: url(../images/webform_thank_you_bg.jpg) center top no-repeat;
      background-size: cover;
      height: 789px;
      padding-top: 125px;
      width: 100%;
      .pane-title {
        color: #fff;
        font-size: 48px;
        font-weight: 300;
        margin: 0 0 40px 0;
        text-align: center;
      }
      .pane-content {
        color: #fff;
        font-size: 24px;
        font-weight: 300;
        .home-link {
          background-color: #f58220;
          color: #fff;
          display: inline-block;
          font-size: 18px;
          font-weight: 300;
          height: 31px;
          line-height: 31px;
          padding: 0 60px;
          text-decoration: none;
        }
      }
    }
  }
}