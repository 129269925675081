
.node-type-portfolio .field-type-text-long p,
.node-type-portfolio .field-type-text-long .spoiler,
.node-type-service .field-type-text-long p,
.node-type-service .field-type-text-long .spoiler,
.node-type-website-development .field-type-text-long p,
.node-type-website-development .field-type-text-long .spoiler,
.node-type-website-development .field-name-field-text-before-services p,
.field-name-field-portfolio-reviews .field-name-body ,
.field-name-field-portfolio-reviews .field-name-body p,
.node-type-m-library .field-name-body p,
.node-type-article .field-name-body p,
.pane-team .pane-custom p,
.pane-about-top p,
.view-v-otzyvy .view-content .views-row .views-field-body,
.view-v-reviews .view-content .views-row .views-field-body,
.view-reviews-slider .views-field-body p,
#mini-panel-advantages .group-advantage p,
.field-type-text-long p,
.front-about-pane p {
	font-size: 16px;
	line-height: 24px;
	color: #555555;
}

.node-type-portfolio .field-type-text-long li,
.node-type-portfolio .field-type-text-long .spoiler li,
.node-type-service .field-type-text-long li,
.node-type-service .field-type-text-long .spoiler li,
.node-type-service .field-name-body li,
.node-type-service .field-name-body .spoiler li,
.node-type-website-development .field-type-text-long li,
.node-type-website-development .field-type-text-long .spoiler li,
.node-type-website-development .field-name-field-text-before-services li,
.field-name-field-portfolio-reviews .field-name-body li,
.node-type-m-library .field-name-body li,
.node-type-article .field-name-body li,
.pane-team .pane-custom li,
.pane-about-top li,
.view-v-otzyvy .view-content .views-row .views-field-body li,
.view-v-reviews .view-content .views-row .views-field-body li,
.view-reviews-slider .views-field-body li,
 #mini-panel-advantages .group-advantage li,
.front-about-pane li {
	font-size: 16px;
	line-height: 24px;
	color: #333333;
}


.node-type-website-development,.node-type-smm,.node-type-portfolio,.node-type-service {
	.paragraphs-items-field-page-top-block {
		.field-type-text-long p {
			line-height: inherit;
			color: inherit;
		}
	}
}

.node-type-portfolio .field-name-field-page-top-block .field-name-field-left-text div{
	font-size: 16px;
	line-height: 24px;
}

.field-name-body.field-type-text-with-summary {
    ul {
      padding-left: 0px;
      > li {
        padding-left: 0px;
        list-style-image: url(../images/list_style_icon1.png);
        margin-bottom: 5px;
        list-style-position: inside;
      }
      ul {
        padding-bottom: 5px;
        padding-left: 14px;
        padding-top: 10px;
        li {
          list-style-image: url(../images/list_style_icon2.png);
          margin-bottom: 5px;
        }
      }
    }
    strong,h2,h4,h5 {
      color: #333333;
    }
  }

  .node-type-portfolio, {
    .paragraphs-items-field-page-top-block-full {
      .field-name-field-left-text {
        p {
          font-size: 30px;
          padding: 25px 0px;
        }
      }
    }
  }
  .node-type-service {
    .field-name-body {
      p {
        font-size: 16px;
        line-height: 24px;
        color: #555555;
      }
    }
  }

  .node-type-website-development {
    .pane-node-field-page-top-block {
      .field-type-text-long li {
        color: white;
      }
    }
  }