.view-portfolio .view-content img{
  border: 1px solid #e3e3e3;
}
.number {
  font-weight: 300;
  line-height: 27px;
  color: #333;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    width: 27px;
    height: 27px;
    background-color: #0e6fad;
    color: white;
    font-size: 18px;
    font-weight: 900;
    line-height: 27px;
    background: #0e6fad;
    display: inline-block;
    text-align: center;
    margin-right: 20px;
  }
}
blockquote {
      background: #fafafc url(../images/quote_icon.png) left center no-repeat;
      border: none;
      padding: 30px 0 30px 45px;
      position: relative;
      color: #7a7a7a;
      font-family: Arial;
      font-size: 18px;
      font-style: italic;
      line-height: 22px;
      &:before {
        background: #fafafc;
        content: '';
        height: 100%;
        left: -25px;
        position: absolute;
        top: 0;
        width: 25px;
      }
      &:after {
        background: #fafafc;
        content: '';
        height: 100%;
        position: absolute;
        right: -25px;
        top: 0;
        width: 25px;
      }
      > * {
        background-color: #eef4f7;
        border-left: 2px solid #f58220;
        display: block;
        padding: 10px 20px;
      }
    }
.h2_blue {
  font-weight: 300;
  line-height: 20px;
  color: #0e6fad;
  font-size: 24px;
  margin-bottom: 18px;
  padding-top: 20px;
}

h3 {
  font-weight: 300;
  line-height: 20px;
  color: #333;
  font-size: 18px;
  margin-bottom: 18px;
}
strong {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.page-portfolio {
  .pane-custom.pane-1 {
    margin-top: 70px;
    h2 {
      font-size: 28px;
      font-weight: 900;
      line-height: 34px;
      text-transform: uppercase;
    }
    .pane-content {
      font-size: 15px;
    }
  }

}

.node-type-portfolio {
  .field-name-field-full-text {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0px 30px;
    padding-bottom: 60px;
    color: #7a7a7a;
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    h2 {
      color: black;
    }
  }
   .hidden_spoiler {
    display: none; 
  }
  .spoiler {
    .open_spoiler {
      background: url(../images/spoiler_open.png) bottom center no-repeat;
      color: #f58220;
      font-size: 13px;
      font-weight: 300;
      line-height: 20px;
      text-decoration: none;
      padding-bottom: 14px;
    }
    .close_spoiler {
      background: url(../images/spoiler_close.png) top no-repeat;
      display: none;
      color: #f58220;
      font-size: 13px;
      font-weight: 300;
      line-height: 20px;
      text-decoration: none;
      padding-top: 14px;
    }
    .show_hide_spoiler {
      text-align: center;
    }
  }
  .field-name-field-portfolio-text-white-bg,.field-name-field-full-text {
    ul {
      padding-left: 18px;
      > li {
        padding-left: 10px;
        list-style-image: url(../images/list_style_icon1.png);
        margin-bottom: 5px;
      }
      ul {
        padding-bottom: 5px;
        padding-left: 14px;
        padding-top: 10px;
        li {
          list-style-image: url(../images/list_style_icon2.png);
          margin-bottom: 5px;
        }
      }
    }
    strong,h2,h4,h5 {
      color: #333333;
    }
  }
}
.pane-works-slider {
  .pane-custom {
    h2 {
      font-size: 28px;
      font-weight: 900;
      line-height: 34px;
      text-transform: uppercase;
    }
    .pane-content {
      font-size: 15px;
      max-width: 840px;
      margin: 0 auto;
      width: 100%;
    }
  }
}
.view-id-portfolio {
  .view-header {
    font-size: 15px;
    max-width: 840px;
    margin: 0 auto;
    width: 100%;
    h2 {
      font-size: 28px;
      font-weight: 900;
      line-height: 34px;
      text-transform: uppercase;
    }
    .pane-content {
      font-size: 15px;
      max-width: 840px;
      margin: 0 auto;
      width: 100%;
    }
    margin-bottom: 20px;
  }
  .ajax-progress {
    display: none;
  }
  .views-exposed-widget {
    float: none;
    padding: 0;
  }
  .view-content {
    margin-top: 47px;
  }
}
.field-name-field-portfolio-reviews {
  background-color: #fff;
  padding: 70px 0 50px;
  > .field-items {
    @extend .container;
    text-align: center;
  }
  .field-name-field-r-photo {
    margin-bottom: 23px;
    img {
      border-radius: 50%;
    }
  }
  .title {
    color: #333;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .field-name-field-r-position {
    color: #0e6fad;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .field-name-body {
    color: #7a7a7a;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    &:before {
      background: url(../images/quote_icon_left.png) center no-repeat;
      content: '';
      height: 17px;
      left: -60px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 18px;
    }
    &:after {
      background: url(../images/quote_icon_right.png) center no-repeat;
      content: '';
      height: 17px;
      right: -60px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 18px;
    }
  }
}

.owl-theme.owl-portfolio {
  position: relative;
  width: calc(100% + 14px);
  .owl-controls {
    margin-top: 30px;
    .owl-page {
      span {
        background: none;
        border: 3px solid $blue;
        border-radius: 2px;
        height: 16px;
        opacity: 0.25;
        width: 16px;
      }
      &:hover,
      &.active {
        span {
          background-color: $blue;
          border: 3px solid $blue;
          border-radius: 2px;
          height: 16px;
          opacity: 1;
          width: 16px;
        }
      }
    }
  }
}
.field-name-field-portfolio-slider {
  margin-bottom: 80px;
  .paragraphs-item-portfolio-slider {
    text-align: center;
    a {
      &:focus {
        outline: none;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    margin: 0 auto;
    position: relative;
    &:hover {
      .field-name-field-portfolio-slider-text {
        display: none;
      }
    }
  }
  .field-name-field-portfolio-slider-text {
    background: rgba(38,125,181,0.9);
    color: #fff;
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    .field-items {
      display: table;
      .field-item {
        display: table-cell;
        font-weight: 300;
        padding: 20px;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
.owl-theme.owl-portfolio-inside {
  .owl-controls {
    .owl-prev,
    .owl-next {
      border: 1px solid $blue;
      border-radius: 0;
      height: 40px;
      margin-top: -20px;
      position: absolute;
      top: 50%;
      width: 40px;
    }
    .owl-prev {
      background: url(../images/certificates_slider_arrow_prev.png) right 8px center no-repeat;
      left: 0;
      &:hover {
        background-image: url(../images/certificates_slider_arrow_prev_hover.png);
        border: 1px solid $yellow;
      }
    }
    .owl-next {
      background: url(../images/certificates_slider_arrow_next.png) left 8px center no-repeat;
      right: 0;
      &:hover {
        background-image: url(../images/certificates_slider_arrow_next_hover.png);
        border: 1px solid $yellow;
      }
    }
  }
}
.node-portfolio-teaser {
  display: inline-block;
  position: relative;
  img {
    height: auto;
    max-width: 100%;
  }
  &:hover {
    .hover-container {
      display: block;
    }
  }
  .hover-container {
    background: rgba(245,130,32,0.95);
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    .table {
      display: table;
      position: relative;
      width: 100%;
      .table-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .search-icon {
      background: url(../images/search_icon.png) center no-repeat;
      display: inline-block;
      height: 55px;
      width: 58px;
      &:focus {
        outline: none;
      }
    }
    .title {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      line-height: 26px;
    }
    .more-container {
      text-align: center;
    }
    .more-btn {
      border: 1px solid #fff;
      color: #fff;
      display: inline-block;
      height: 35px;
      line-height: 35px;
      text-align: center;
      text-decoration: none;
      width: 184px;
    }
  }
}
.node-portfolio-full {
  .paragraphs-items-field-page-top-block-full {
    @extend .clearfix;
    width: 100%;
  }
  .field-name-field-portfolio-text-white-bg {
    @extend .container;
    background: #fff;
    font-size: 14px;
    font-weight: 300;
    padding: 30px 20px;
  }
  .field-name-field-portfolio-before-slider {
    @extend .container;
    padding: 50px 0;
    h2 {
      font-size: 30px;
      font-weight: 300;
    }
    img {
      height: auto;
      max-width: 100%;
    }
  }
  .field-name-field-portfolio-slider {
    @extend .container;
  }
  .field-name-field-portfolio-team-text {
    @extend .container;
    max-width: 700px;
    padding-bottom: 30px;
    padding-top: 70px;
  }
  .field-name-field-portfolio-team {
    @extend .container;
    padding-bottom: 65px;
  }
}

@include respond-to('small') {
  .pane-works-slider {
    padding: 60px 0 10px 0;
    .pane-custom {
      padding: 0 10px;
    }
  }
  .view-id-portfolio {
    margin: 30px auto 0 auto;
    width: 300px;
    &.view-display-id-page {
      padding-bottom: 30px;
      .views-row {
        padding: 7px 0;
        text-align: center;
      }
    }
  }
  .node-portfolio-teaser {
    .hover-container {
      .table {
        height: 300px;
        .table-cell {
          height: 300px;
          padding: 25px;
        }
      }
      .title {
        margin: 25px 0;
      }
    }
  }
  .node-portfolio-full {
    .field-name-field-portfolio-text-white-bg {
      padding: 20px 10px;
    }
    .field-name-field-portfolio-before-slider {
      padding: 30px 0;
    }
    .field-name-field-portfolio-team-text {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .owl-theme.owl-portfolio {
    left: 0;
    position: relative;
    width: 100%;
    .owl-item {
      .node-portfolio-teaser {
        margin: 7px auto;
      }
    }
  }
  .field-name-field-portfolio-slider {
    margin-bottom: 40px;
    padding: 0 0 47px 0;
    .paragraphs-item-portfolio-slider {
      height: 93px;
      width: 93px;
    }
    .field-name-field-portfolio-slider-text {
      height: 93px;
      width: 93px;
      .field-items {
        height: 93px;
        width: 93px;
        .field-item {
          font-size: 11px;
          height: 93px;
          line-height: normal;
          padding: 0;
          width: 93px;
        }
      }
    }
    .owl-controls {
      .owl-prev {
        bottom: 0;
        left: 50%;
        margin: 0 0 0 -50px;
        top: auto;
      }
      .owl-next {
        bottom: 0;
        margin: 0 -50px 0 0;
        right: 50%;
        top: auto;
      }
    }
  }
  .field-name-field-portfolio-reviews {
    .field-name-body {
      &:before {
        left: -25px;
      }
      &:after {
        right: -25px;
      }
    }
  }
}
@include respond-to('medium') {
  .pane-works-slider {
    padding: 50px 0;
  }
  .view-id-portfolio {
    margin-top: 30px;
    padding: 0 25px;
    &.view-display-id-page {
      padding-bottom: 50px;
      .pagination {
        margin-top: 30px;
      }
      .views-row {
        padding: 7px 0;
        &:nth-child(2n+1) {
          text-align: left;
        }
        &:nth-child(2n) {
          text-align: right;
        }
      }
    }
  }
  .node-portfolio-teaser {
    .hover-container {
      .table {
        height: 330px;
        .table-cell {
          height: 330px;
          padding: 35px;
        }
      }
      .title {
        margin: 25px 0;
      }
    }
  }
  .node-portfolio-full {
    .field-name-field-portfolio-text-white-bg {
      padding: 30px 20px;
    }
    .field-name-field-portfolio-before-slider {
      padding: 40px 0;
    }
    .field-name-field-portfolio-team-text {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .owl-theme.owl-portfolio {
    .owl-item {
      .node-portfolio-teaser {
        float: none !important;
        margin: 7px auto !important;
      }
    }
  }
  .field-name-field-portfolio-slider {
    margin-bottom: 40px;
    padding: 0 60px;
    .paragraphs-item-portfolio-slider {
      height: 142px;
      width: 142px;
    }
    .field-name-field-portfolio-slider-text {
      height: 142px;
      width: 142px;
      .field-items {
        height: 142px;
        width: 142px;
        .field-item {
          font-size: 16px;
          height: 142px;
          line-height: normal;
          padding: 10px;
          width: 142px;
        }
      }
    }
  }
  .field-name-field-portfolio-reviews {
    .field-name-body {
      &:before {
        left: -25px;
      }
      &:after {
        right: -25px;
      }
    }
  }
}
@include respond-to('large') {
  .pane-works-slider {
    padding: 60px 0;
  }
  .view-id-portfolio {
    &.view-display-id-page {
      padding-bottom: 80px;
      .pagination {
        margin-top: 50px;
      }
      .views-row {
        padding: 7px 0;
        &:nth-child(3n+1) {
          text-align: left;
        }
        &:nth-child(3n+2) {
          text-align: center;
        }
        &:nth-child(3n+3) {
          text-align: right;
        }
      }
      .text-center .item-list {
        clear: both;
      }
    }
  }
  .node-portfolio-teaser {
    .hover-container {
      .table {
        height: 330px;
        .table-cell {
          height: 330px;
          padding: 35px;
        }
      }
      .title {
        margin: 25px 0;
      }
    }
  }
  .owl-theme.owl-portfolio {
    .owl-item {
      .node-portfolio-teaser {
        margin: 7px 0;
      }
    }
  }
  .owl-theme.owl-portfolio-inside {
    padding: 0 74px;
  }
  .field-name-field-portfolio-slider {
    .paragraphs-item-portfolio-slider {
      height: 200px;
      width: 200px;
    }
    .field-name-field-portfolio-slider-text {
      height: 200px;
      width: 200px;
      .field-items {
        height: 200px;
        width: 200px;
        .field-item {
          font-size: 20px;
          height: 200px;
          line-height: 30px;
          width: 200px;
        }
      }
    }
  }
}





