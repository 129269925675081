.node-type-service {
  .field-name-body {
    .field-items {
      @extend .container;
      .field-item {
        @extend .formatter-text;
        background-color: #fff;
        padding: 43px 25px;
      }
    }
  }
}