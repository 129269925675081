.owl-theme.owl-theme-team {
  padding-bottom: 43px;
  .owl-item {
    padding-bottom: 100px;
    .node-team-teaser {
      margin: 0 auto;
    }
  }
  .owl-controls {
    .owl-prev,
    .owl-next {
      border: 1px solid $blue;
      border-radius: 0;
      bottom: 0;
      height: 40px;
      position: absolute;
      width: 40px;
    }
    .owl-prev {
      background: url(../images/certificates_slider_arrow_prev.png) right 8px center no-repeat;
      right: 50%;
      margin-right: 15px;
      &:hover {
        background-image: url(../images/certificates_slider_arrow_prev_hover.png);
        border: 1px solid $yellow;
      }
    }
    .owl-next {
      background: url(../images/certificates_slider_arrow_next.png) left 8px center no-repeat;
      left: 50%;
      margin-left: 15px;
      &:hover {
        background-image: url(../images/certificates_slider_arrow_next_hover.png);
        border: 1px solid $yellow;
      }
    }
  }
}
.node-team-teaser {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  height: 410px;
  padding: 10px;
  position: relative;
  width: 320px;
  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    .info-container {
      background-color: $yellow;
      color: #fff;
      .more-btn {
        display: block;
      }
      .title {
        color: #fff;
      }
    }
  }
  .info-container {
    background-color: #fff;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    bottom: -55px;
    color: #7a7a7a;
    font-size: 15px;
    line-height: 20px;
    left: 26px;
    min-height: 100px;
    padding: 20px 30px 12px 30px;
    position: absolute;
    text-align: center;
    width: 268px;
    z-index: 2;
    .title {
      color: $black;
      font-size: 20px;
      line-height: 24px;
    }
    .more-btn {
      border: 1px solid #fff;
      color: #fff;
      display: none;
      font-size: 15px;
      font-weight: 300;
      height: 40px;
      line-height: 40px;
      margin: 25px 0 12px 0;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}
.node-team-full {
  background-color: #fff;
  margin-bottom: 65px;
  padding: 73px 45px 25px 45px;
  .left-col {
  }
  .right-col {
    padding-left: 45px;
  }
  .field-name-field-team-photo {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 26px;
    padding: 10px;
    img {
      height: auto;
      width: 100%;
    }
  }
  .colorbox {
    background-color: #0e6fad;
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: 300;
    height: 48px;
    line-height: 48px;
    margin-bottom: 26px;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  .field-name-body {
    @extend .formatter-text;
    h1 {
      font-size: 30px;
      font-weight: bold;
      margin-top: 0;
      text-transform: uppercase;
      span {
        display: inline-block;
      }
    }
    strong {
      font-weight: 500;
    }
  }
}

@include respond-to('small') {
  .node-team-full {
    padding: 50px 10px 25px 10px;
    .right-col {
      padding-left: 0;
    }
  }
  .node-team-teaser {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    .info-container {
      background-color: #f58220;
      color: #fff;
      .title {
        color: #fff;
      }
      .more-btn {
        display: block;
      }
    }
  }
}
@include respond-to('medium') {
  .owl-theme.owl-theme-team {
    .owl-item {

    }
  }
  .node-team-full {
    padding: 73px 20px 25px 20px;
    .right-col {
      padding-left: 20px;
    }
  }
  .node-team-teaser {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    .info-container {
      background-color: #f58220;
      color: #fff;
      .title {
        color: #fff;
      }
      .more-btn {
        display: block;
      }
    }
  }
}
@include respond-to('large') {
  .owl-theme.owl-theme-team {
    margin-left: -16px;
    width: 1056px;
  }
}
