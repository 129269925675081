.pane-about-top {
  background: url(../images/about_top_bg.jpg) center no-repeat;
  background-size: cover;
  padding: 70px 0 0 0;
  .bg-white {
    padding: 40px 25px;
  }
}
.pane-vortex-numbers {
  background: url(../images/about_numbers_bg.jpg) center no-repeat;
  background-size: cover;
  padding: 75px 0;
  .pane-header-row {
    .pane-header-right {
      font-size: 30px;
      line-height: 36px;
      padding: 20px 140px 0 40px;
    }
  }
  .pane-body-row {
    margin-top: 80px;
    padding: 90px 60px;
  }
}
.pane-panels-mini.pane-certificates {
  background: #fff;
  .pane-custom {
    h2 {
      color: $black;
      font-size: 30px;
      font-weight: 900;
      line-height: 50px;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
.owl-theme.owl-certificates-slider {
  .owl-item {
    padding: 30px 0;
    text-align: center;
    a {
      &:focus {
        outline: none;
      }
    }
    img {
      border: 2px solid #f5f5f5;
      filter: grayscale(100%);
      height: auto;
      max-width: 100%;
      &:hover {
        box-shadow: 0 8px 13px rgba(0,0,0,0.25);
      }
    }
  }
  .owl-controls {
    .owl-prev {
      background: url(../images/certificates_slider_arrow_prev.png) center no-repeat;
      height: 30px;
      margin-right: 200px;
      position: absolute;
      right: 50%;
      top: -57px;
      width: 26px;
      &:hover {
        background: url(../images/certificates_slider_arrow_prev_hover.png) center no-repeat;
      }
    }
    .owl-next {
      background: url(../images/certificates_slider_arrow_next.png) center no-repeat;
      height: 30px;
      left: 50%;
      margin-left: 200px;
      position: absolute;
      top: -57px;
      width: 26px;
      &:hover {
        background: url(../images/certificates_slider_arrow_next_hover.png) center no-repeat;
      }
    }
  }
}


@include respond-to('small') {
  .pane-about-top {
    .pane-content {
      padding: 0 10px;
    }
    padding: 50px 0 0 0;
    .bg-white {
      padding: 30px 10px;
    }
  }
  .pane-vortex-numbers {
    background: $blue;
    padding: 0;
    .pane-header-row {
      padding-top: 50px;
      padding-left: 10px;
      padding-right: 10px;
      .pane-header-right {
        font-size: 24px;
        line-height: 30px;
        padding: 35px 0 0 0;
      }
    }
    .pane-body-row {
      margin-top: 40px;
      padding: 30px 10px;
      .col-sm-12 {
        padding: 23px 0;
        .rtecenter:nth-child(1) {
          margin-left: auto;
          margin-right: auto;
          padding-left: 67px;
          text-align: left;
          width: 238px;
          img {
            transform: translate(-50%, 0);
          }
        }
      }
    }
  }
  /* .owl-theme.owl-certificates-slider {
    margin-left: auto;
    margin-right: auto;
    width: 264px;
    .owl-wrapper-outer {
      overflow: visible;
    }
  } */
  .owl-theme.owl-certificates-slider {
    .owl-controls {
      .owl-prev {
        left: 0;
        margin-top: -15px;
        top: 50%;
      }
      .owl-next {
        left: auto;
        margin-top: -15px;
        right: 0;
        top: 50%;
      }
    }
  }
}

@include respond-to('medium') {
  .pane-about-top {
    padding: 60px 0 0 0;
    .bg-white {
      padding: 35px 20px;
    }
  }
  .pane-vortex-numbers {
    background: $blue;
    padding: 0;
    .pane-header-row {
      padding-top: 50px;
      .pane-header-right {
        font-size: 24px;
        line-height: 30px;
        padding: 35px 0 0 0;
      }
    }
    .pane-body-row {
      display: inline-block;
      float: left;
      margin-top: 50px;
      padding: 100px 0;
      position: relative;
      width: 100%;
      &:before {
        background-color: #f58220;
        content: '';
        display: block;
        left: -500px;
        height: 100%;
        position: absolute;
        top: 0;
        width: 500px;
      }
      &:after {
        background-color: #f58220;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: -500px;
        top: 0;
        width: 500px;
      }
    }
  }
}

@include respond-to('large') {

}



