/**
$yellow:  #f58220;
$black:   #333333;
$grey:    #7a7a7a;
$blue:    #0e6fad;
$white:   #ffffff;
**/
.shadow {
  height: 20px;
  position: absolute;
  top: 47px;
  z-index: 2;
  width: 100%;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 50% / 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 5px 20px;
    clip: rect(10px, auto, 50px, 0);
  }
}

#header {
  //background: #fff;
  height: 133px;
  padding: 6px 0px;
  position: relative;
  z-index: 600;

  &:after {
    background: url('../images/header_bottom_shadow.png') no-repeat center;
    display: block;
    width: 100%;
    position: absolute;
    bottom: -19px;
    height: 19px;
    content: ' '
  }
  

  .col-header-left {
    line-height: 55px;
    //padding-right: 30px;
    a.logo-link {
      display: inline-block;
      float: left;
      img.logo {
        height: auto;
        max-width: 100%;
      }
    }
    .block {
      display: inline-block;
      float: left;
      padding-left: 39px;
      padding-top: 8px;
    }
    .block__content {
      font-size: 30px;
      font-weight: 300;
      text-align: right;
      .social {
        .fa {
          background-color: #7db0d2;
          color: white;
          display: inline-block;
          font-size: 18px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          width: 30px;
          border-radius: 50%;
        }
      }
      .yellow {
        padding-left: 12px;
        font-size: 24px;
      }
      .black {
        font-size: 24px;
      }
    }
    .block-uworld-popup{ 
      padding: 0px;
      padding-top: 2px;
      float: right;
      position: relative;
      top: 5px;
      .block__content {
        a{
          background-color: #0e6fad;
          color: #fff;
          display: inline-block;
          font-size: 16px;
          font-weight: 300;
          height: 30px;
          line-height: 30px;
          margin: 10px 0 0 0;
          text-decoration: none;
          text-align: center;
          width: 343px;
        }
      }
    }
  }
  .col-header-right {
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    .block {
      display: inline-block;
    }
    .ctools-use-modal {
      background-color: #0e6fad;
      color: #fff;
      display: inline-block;
      font-size: 16px;
      font-weight: 300;
      height: 30px;
      line-height: 30px;
      margin: 10px 0 0 0;
      text-decoration: none;
      text-align: center;
      width: 343px;
      &:focus {
        outline: none;
      }
      img {
        display: none;
      }
    }
    #main-menu {
      float: left;
      margin: 0px; 

      li {
        display: inline-block;
      }
      a{
        color: #333;
        font-family: HelveticaNeueCyr;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        padding: 8px 27px; 
        &:hover {
          color: #f58220;
          background: none;
        }
      }
    }
  }
}
#left-menu-open {
  background-color: #f58220;
  //border-radius: 3px;
  //bottom: -42px;
  color: #fff;
  cursor: pointer;
  font-size: 21px;
  height: 46px;
  left: 50%;
  line-height: 46px;
  position: absolute;
  text-align: center;
  //transform: translate(-50%, 0);
  width: 163px;
  bottom: 0px;
    right: 0px;
    left: auto;
}
#left-menu-container {
  background-color: #08305d;
  bottom: 0;
  position: fixed;
  right: -275px;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 275px;
  z-index: 601;
  .inner {
    color: #fff;
    height: calc(100% - 70px);
    overflow: auto;
    padding: 0 15px 50px 15px;
  }
  .close {
    background: #0a4375 url(../images/close_menu_icon.png) right 70px center no-repeat;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    height: 70px;
    line-height: 70px;
    opacity: 1;
    text-indent: 90px;
    text-shadow: none;
    width: 100%;
  }
  .menu-services-block {
    margin-top: 29px;
    .block__content {
      a {
        color: #fff;
      }
      .start-collapsed {
        > a {
          background: rgba(38,125,181,1);
        }
      }
      > ul {
        margin: 0;
        padding: 0;
        > li {
          display: inline-block;
          margin: 3px 0;
          width: 100%;
          > a {
            background-color: #f58220;
            box-shadow: 0 4px 9px rgba(0, 0, 0, 0.35);
            border-radius: 3px;
            color: #fff;
            display: block;
            font-size: 17px;
            font-weight: 400;
            height: 45px;
            line-height: 45px;
            padding: 0;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            .caret {
              display: none;
            }
          }
          > ul {
            margin: 0;
            padding: 30px 0;
            li {
              display: inline-block;
              margin: 3px 0;
              width: 100%;
              ul {
                margin: 13px 0 10px 0;
              }
              a {
                background: rgba(38,125,181,0.3);
                border-radius: 3px;
                font-size: 18px;
                font-weight: 300;
                padding: 3px 10px;
                position: relative;
                &:hover {
                  background: rgba(38,125,181,1);
                }
                .caret {
                  background: url(../images/caret_icon.png) center no-repeat;
                  border: none;
                  display: inline-block;
                  height: 9px;
                  margin-top: -5px;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  width: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
#menu-burger {
  background: url(../images/vortex_burger.png) center no-repeat;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 25px;
  margin-top: 12px;
  width: 28px;
  display: none;
}
#menu-close-btn {
  background: url(../images/menu_close_icon.jpg) center no-repeat;
  cursor: pointer;
  display: none;
  float: right;
  height: 40px;
  position: relative;
  top: 4px;
  width: 40px;
}
#menu-mobile-container {
  background-color: #08305d;
  bottom: 0;
  position: fixed;
  left: -275px;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 275px;
  z-index: 601;
  .inner {
    color: #fff;
    height: calc(100% - 70px);
    overflow: auto;
    padding: 0 15px 50px 15px;
  }
  .close {
    background: #0a4375 url(../images/close_menu_icon.png) right 70px center no-repeat;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    height: 70px;
    line-height: 70px;
    opacity: 1;
    text-indent: 90px;
    text-shadow: none;
    width: 100%;
  }
  .block-menu {
    .block__content {
      a {
        color: #fff;
      }
      .start-collapsed {
        > a {
          background: rgba(38,125,181,1);
        }
      }
      > ul {
        margin: 0;
        padding: 0;
        > li {
          display: inline-block;
          margin: 3px 0;
          width: 100%;
          > a {
            display: block;
            font-size: 18px;
            font-weight: 300;
            padding: 10px;
            text-align: center;
            &:hover {
              background: rgba(38,125,181,1);
            }
            .caret {
              background: url(../images/caret_icon.png) center no-repeat;
              border: none;
              display: inline-block;
              height: 9px;
              margin-left: 20px;
              width: 17px;
            }
          }
          > ul {
            margin: 0;
            padding: 30px 0;
            li {
              display: inline-block;
              margin: 3px 0;
              width: 100%;
              ul {
                margin: 13px 0 10px 0;
              }
              a {
                background: rgba(38,125,181,0.3);
                font-size: 18px;
                font-weight: 300;
                padding: 3px 10px;
                position: relative;
                &:hover {
                  background: rgba(38,125,181,1);
                }
                .caret {
                  background: url(../images/caret_icon.png) center no-repeat;
                  border: none;
                  display: inline-block;
                  height: 9px;
                  margin-top: -5px;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  width: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.left-menu-block-info {
  margin-top: 10px;
  .block__content {
    color: rgba(255,255,255,.47);
    font-size: 24px;
    font-weight: 300;
    .white {
      color: rgba(255,255,255,.47);
    }
    .social {
      display: inline-block;
      margin-top: 15px;
      .fa {
        /*background-color: rgba(255,255,255,.47);
        color: $black;
        display: inline-block;
        font-size: 23px;
        height: 31px;
        line-height: 31px;
        margin: 0 10px;
        text-align: center;
        width: 31px;*/
		background-color: #7db0d2;
		color: white;
		display: inline-block;
		font-size: 18px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		width: 30px;
		border-radius: 50%;
      }
    }
  }
}
.left-menu-modal-link-block {
  margin: 27px 0 15px 0;
  .block__content {
    a {
      background-color: #f58220;
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.35);
      border-radius: 3px;
      color: #fff;
      display: block;
      font-size: 17px;
      font-weight: 400;
      height: 45px;
      line-height: 45px;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  }
}
body {
  &.menu-open {
    // overflow: hidden;
    #menu-burger {
      display: none;
    }
    #menu-close-btn {
      display: inline-block;
    }
    #menu-mobile-container {
      left: 0;
    }
  }
  &.left-menu-open {
    #left-menu-container {
      right: 0;
    }
  }
}

.admin-menu {
  .shadow {
    top: 76px;
  }
  #left-menu-container,
  #menu-mobile-container {
    top: 29px;
  }
}


@media (min-width: 1025px) and (max-width: 1366px) {
  /*#header {
    .col-header-left {
      padding-right: 10px;
      a.logo-link {
        width: 200px;
      }
      .block__content {
        font-size: 25px;
      }
    }
    .col-header-right {
      padding-left: 10px;
    }
  }*/
}
@media (min-width: 1367px) {
  #header {
    .col-header-left {
      .block__content {
        .social {
          margin-right: 3px;
        }
      }
    }
  }
}

@include respond-to('small') {
  #header {
    .container {
	    padding-left: 10px;
	    padding-right: 10px;
    }
    height: 163px;
    .col-header-left {
    	height: 111px;
      a.logo-link {
        width: 300px;
		position: absolute;
		top: 43px;
      }
      .block__content {
        display: block;
        text-align: center;
      }
      .block {
		float: none;
		display: block;
		text-align: center;
		padding: 0px;
		line-height: 42px;
      }
      .block-uworld-popup {
      	display: none;
      }
    }
    .col-header-right {
      .block__content {
        display: none;
      }
      #main-menu {
      	display: none;
      }
      #left-menu-open {
		position: relative;
		float: right;
      }
      #menu-burger {
      	display: block;
	    float: left;
	    margin-left: 22px;
      }
    }
  }
}

@include respond-to('medium') {
  #header {
    padding-left: 20px;
    padding-right: 20px;
    height: 122px;
    .col-header-left {
      a.logo-link {
        width: 304px;
      }
      .block-block {
      	float: right;
      	padding-top: 5px;
      }
      .block-uworld-popup {
      	display: none;
      }
    }
    .col-header-right {
      .block__content {
        display: none;
      }
      #main-menu {
      	display: none;
      }
      #left-menu-open {
		position: relative;
		float: right;
      }
      #menu-burger {
      	display: block;
	    float: left;
	    margin-left: 22px;
      }
    }
  }
}

@include respond-to('large') {

}
