.node-type-website-development {
  .pane-works-slider {
    padding-bottom: 0;
  }
}
.field-name-field-text-before-services {
  padding: 43px 0 35px 0;
  .field-items {
    @extend .container;
    .field-item {
      @extend .formatter-text;
      margin: 0 auto;
      max-width: 830px;
      width: 100%;
    }
  }
}
.field-name-field-text-after-services {
  overflow: hidden;
  width: 100%;
  .field-items {
    @extend .container;
    .field-item {
      background-color: #fff;
      padding: 43px 25px;
      position: relative;
      @extend .formatter-text;
      &:before {
        background-color: #fff;
        bottom: 0;
        content: '';
        height: 46px;
        left: -1000px;
        position: absolute;
        width: 1000px;
      }
      &:after {
        background-color: #fff;
        bottom: 0;
        content: '';
        height: 46px;
        right: -1000px;
        position: absolute;
        width: 1000px;
      }
    }
  }
}
.field-name-field-text-before-map {
  .field-items {
    @extend .container;
    .field-item {
      @extend .formatter-text;
      background-color: #fff;
      margin-bottom: 45px;
      padding: 30px 25px;
    }
  }
}

.pane-section-services-panel-pane-1 {
  .pane-content {
    @extend .container;
  }
}
.view-id-section_services {
  margin-bottom: 20px;
  .view-content {
    text-align: center;
    .views-row {
      height: 405px;
      display: inline-block;
      letter-spacing: normal;
      position: relative;
      padding: 45px 40px;
      vertical-align: top;
    }
    .views-field-field-service-icon {
      margin-bottom: 15px;
    }
    .image-icon-hover {
      display: none;
    }
    .views-field-title {
      color: #333;
      font-size: 26px;
      font-weight: 300;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .views-field-field-service-desc {
      color: #7a7a7a;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 13px;
    }
    .views-field-field-service-price-days {
      color: #333;
      font-size: 18px;
      font-weight: 900;
      line-height: 20px;
    }
    .views-field-view-node {
      bottom: 45px;
      left: 0;
      position: absolute;
      text-align: center;
      width: 100%;
      a {
        background-color: #1973af;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        font-weight: 300;
        height: 35px;
        line-height: 35px;
        text-align: center;
        text-decoration: none;
        width: 184px;
      }
    }
  }
}


//Цены
.prices-accordion-title-pane {
  padding-top: 60px;
  .pane-content {
    @extend .container;
    h2 {
      font-size: 28px;
      font-weight: 900;
      line-height: 34px;
      margin: 0;
      text-transform: uppercase;
    }
  }
}
.pane-prices-sections-services-panel-pane-1 {
  .pane-content {
    @extend .container;
  }
}
.view-id-prices_sections_services {
  margin: 40px 0 80px 0;
  .view-header {
    padding-top: 20px;
    padding-bottom: 40px;
    h2 {
      font-size: 28px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .views-accordion-header {
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    margin: 10px 0 0 0;
    padding: 10px 60px 10px 30px;
    position: relative;
    .ui-accordion-header-icon {
      background: url(../images/accardion_icon.png) center no-repeat;
      height: 9px;
      left: auto;
      margin: 0;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translate(0, -50%);
      width: 13px;
    }
    a {
      color: #333;
      font-size: 20px;
      font-weight: 300;
    }
    &.ui-state-active {
      .ui-accordion-header-icon {
        background: url(../images/accardion_icon_active.png) center no-repeat;
      }
      a {
        color: $yellow;
      }
    }
  }
  .ui-accordion-content {
    background: none;
    border: none;
    border-radius: 0;
    overflow: visible;
    padding: 25px 0;
  }
}
.view-id-prices_accardion_data {
  @extend .view-id-v_price;
  padding: 0;
  .view-content {
    padding: 0;
    .views-row.views-row-last {
      margin-bottom: 0;
    }
  }
}


//тарифы
.field-name-field-smm-tariff {
  background-color: #fff;
  padding: 80px 0 60px 0;
  .field-items {
    @extend .container;
  }
  .tariff-header {
    div[class*='col-'] {
      padding-top: 0px;
    }
    .info {
      background: url(../images/tariff_info_header_bg.png) center no-repeat;
    }
    .tariff-name {
      color: #333;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .tariff-desc {
      margin-bottom: 10px;
    }
    .tariff-price {
      color: #333;
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
    }
    a {
      background-color: #1973af;
      color: #fff;
      display: none;
      font-size: 18px;
      font-weight: 300;
      height: 35px;
      line-height: 35px;
      margin: 10px 0;
      text-align: center;
      text-decoration: none;
      width: 184px;
      &:hover {
        background-color: $yellow;
      }
    }
  }
  .row {
    align-items: stretch;
    display: flex;
    justify-content: center;
  }
  div[class*='col-'] {
    color: #7a7a7a;
    font-size: 14px;
    padding: 10px;
    position: relative;
    text-align: center;
  }
  .info-icon {
    background: url(../images/info_icon.png) center no-repeat;
    cursor: pointer;
    height: 18px;
    float: right;
    width: 18px;
    &:hover {
      background: url(../images/info_icon_hover.png) center no-repeat;
      .info-hover {
        display: block;
      }
    }
    .info-hover {
      box-shadow: -2px 2px 10px rgba(0,0,0,.1);
      background: #fff;
      display: none;
      font-size: 13px;
      font-weight: 300;
      left: 0;
      line-height: 17px;
      padding: 20px;
      position: absolute;
      text-align: left;
      top: -5px;
      transform: translate(0, -100%);
      width: 100%;
      &:before {
        background: url(../images/info_icon_hover_before.png) center no-repeat;
        bottom: -23px;
        content: '';
        height: 23px;
        position: absolute;
        right: 15px;
        width: 23px;
      }
    }
  }
  .tariff-footer {
    div[class*='col-'] {
      padding-top: 20px;
      padding-bottom: 0;
    }
    a {
      background-color: #1973af;
      color: #fff;
      display: inline-block;
      font-size: 18px;
      font-weight: 300;
      height: 35px;
      line-height: 35px;
      text-align: center;
      text-decoration: none;
      width: 184px;
      &:hover {
        background-color: $yellow;
      }
    }
  }
}

.field-name-field-smm-text-before-working {
  color: #7a7a7a;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  padding: 50px 0;

  .field-items {
    @extend .container;
  }
  h2 {
    color: #333;
    font-size: 30px;
    font-weight: 300;
    line-height: normal;
  }
  .number {
    background-color: #0e6fad;
    color: #fff;
    display: inline-block;
    float: left;
    font-size: 18px;
    font-weight: 900;
    height: 27px;
    line-height: 27px;
    text-align: center;
    vertical-align: middle;
    width: 27px;
    margin-top: 0px;
    margin-bottom: 0px;
    + span {
      float: left;
      display: inline-block;
      width: calc(100% - 40px);
    }
  }
}


@include respond-to('small') {
  .view-id-section_services {
    .view-content {
      padding: 0 10px;
      .views-row {
        width: 100%;
      }
      .views-field-view-node {
        a:hover {
          background-color: #f58220;
        }
      }
    }
  }
  .view-id-prices_sections_services {
    padding-left: 10px;
    padding-right: 10px;
  }
  .field-name-field-smm-tariff {
    padding: 60px 0 50px 0;
    .field-items {
      padding-left: 20px;
      padding-right: 20px;
    }
    .tariff-body,
    .tariff-footer {
      display: none;
    }
    .tariff-header {
      a {
        display: inline-block;
      }
    }
    .row {
      display: inline-block;
      width: 100%;
      div[class*='col-'] {
        margin-bottom: 30px;
        width: 100%;
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
  .field-name-field-smm-text-before-working {
    .field-items {
      padding-left: 10px;
      padding-right: 10px;
    }
    .row {
      .col-sm-12 {
        text-align: left;
        > div {
          clear: both;
          display: inline-block;
          margin: 5px 0;
          width: 100%;
        }
        .number {
          float: left;
          margin-right: 10px;
        }
      }
    }
  }
}

@include respond-to('medium') {
  .view-id-section_services {
    .view-content {
      padding: 0 37px;
      .views-row {
        width: 49%;
        &:nth-child(n+3) {
          &:before {
            background-color: #e1e1e1;
            top: 0;
            content: '';
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translate(-50%, 0);
            width: 184px;
          }
        }
        &:nth-child(2n) {
          &:after {
            background-color: #e1e1e1;
            content: '';
            height: 315px;
            left: 0;
            position: absolute;
            transform: translate(0, -50%);
            top: 50%;
            width: 1px;
          }
        }
        &:hover {
          background-color: #fff;
          box-shadow: 0 8px 13px rgba(0, 0, 0, 0.2);
          z-index: 2;
          &:before,
          &:after {
            display: none;
          }
          .views-field-view-node {
            a {
              background-color: #f58220;
            }
          }
          .image-icon {
            display: none;
          }
          .image-icon-hover {
            display: inline-block;
          }
        }
      }
    }
  }
  .view-id-prices_sections_services {
    padding-left: 20px;
    padding-right: 20px;
  }
  .field-name-field-smm-tariff {
    padding: 60px 0 50px 0;
    .field-items {
      padding-left: 20px;
      padding-right: 20px;
    }
    .tariff-body {
      display: none;
    }
    .row {
      div[class*='col-'] {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
  .field-name-field-smm-text-before-working {
    .field-items {
      padding-left: 20px;
      padding-right: 20px;
    }
    .row {
      .col-md-6 {
        > div {
          clear: both;
          display: inline-block;
          margin: 5px 0;
          width: 100%;
        }
        &:nth-child(1) {
          padding-right: 10px;
          text-align: right;
          .number {
            float: right;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          padding-left: 10px;
          text-align: left;
          .number {
            float: left;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@include respond-to('large') {
  .view-id-section_services {
    .view-content {
      padding: 0 37px;
      .views-row {
        width: 33%;
        &:nth-child(n+4) {
          &:before {
            background-color: #e1e1e1;
            content: '';
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translate(-50%, 0);
            top: 0;
            width: 184px;
          }
        }
        &:nth-child(3n+2),
        &:nth-child(3n+3) {
          &:after {
            background-color: #e1e1e1;
            content: '';
            height: 315px;
            left: 0;
            position: absolute;
            transform: translate(0, -50%);
            top: 50%;
            width: 1px;
          }
        }
        &:hover {
          background-color: #fff;
          box-shadow: 0 8px 13px rgba(0, 0, 0, 0.2);
          z-index: 2;
          &:before,
          &:after {
            display: none;
          }
          .views-field-view-node {
            a {
              background-color: #f58220;
            }
          }
          .image-icon {
            display: none;
          }
          .image-icon-hover {
            display: inline-block;
          }
        }
      }
    }
  }
  .field-name-field-smm-tariff {
    .row {
      div[class*='col-'] {
        border-top: 1px solid #e1e1e1;
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          border-left: 1px solid #e1e1e1;
        }
      }
    }
    .tariff-header {
      .row {
        div[class*='col-'] {
          border-top: none !important;
        }
      }
    }
  }
  .field-name-field-smm-text-before-working {
    .row {
      .col-lg-6 {
        > div {
          clear: both;
          display: inline-block;
          margin: 5px 0;
          width: 100%;
        }
        &:nth-child(1) {
          padding-right: 10px;
          text-align: right;
          .number {
            float: right;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          padding-left: 10px;
          text-align: left;
          .number {
            float: left;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
