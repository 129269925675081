.front {
  .pane-panels-mini.pane-certificates {
    padding-bottom: 40px;
  }
}
.front-top-pane {
  background: url(../images/front_top_bg.jpg) center no-repeat;
  background-size: cover;
  height: 930px;
  .content-left {
    padding-top: 160px;
    min-width: 320px;
    width: 50%;
    .rectangle {
      background: url(../images/rectangle_front_top.png) right 50px center no-repeat;
      color: #fff;
      font-size: 48px;
      font-weight: 300;
      height: 160px;
      margin: 0 0 20px 0;
      padding: 42px 0 0 0;
    }
    .desktop-only {
      color: #fff;
      font-size: 30px;
      font-weight: 300;
    }
    .more {
      background-color: #0e6fad;
      color: #fff;
      display: inline-block;
      font-size: 18px;
      font-weight: 300;
      height: 31px;
      line-height: 31px;
      margin-top: 23px;
      padding: 0 40px;
      text-decoration: none;
    }
    .row {
      margin-top: 60px;
    }
    .col-lg-4 {
      height: 160px;
      padding: 10px;
      left: -10px;
      position: relative;
      width: 160px;
    }
    .link-block {
      background-color: rgba(245,130,32,.8);
      color: #fff;
      display: block;
      font-size: 15px;
      font-weight: 300;
      height: 140px;
      line-height: 18px;
      left: 10px;
      padding: 18px;
      position: absolute;
      top: 10px;
      text-align: center;
      text-decoration: none;
      transition: all 0.2s ease;
      width: 140px;
      &:hover {
        background-color: $yellow;
        left: 5px;
        height: 150px;
        padding: 23px;
        top: 5px;
        width: 150px;
      }
    }
    .image-container {
      display: inline-block;
      height: 36px;
      margin-bottom: 10px;
      vertical-align: middle;
    }
  }
}
.front-about-pane {
  background: #fff;
  padding: 163px 0 126px 0;
  .container {
    min-height: 644px;
    position: relative;
    &:before {
      background: url(../images/front_about_image.png) center no-repeat;
      content: '';
      height: 644px;
      left: 50%;
      position: absolute;
      top: 0;
      width: 603px;
    }
  }
}
.pane-advantages {
  background: url(../images/advantages_bg.jpg) center no-repeat;
  background-size: cover;
  padding: 60px 0;
  position: relative;
  .pane-content {
    position: relative;
    z-index: 2;
  }
  .field-name-field-advantages-title {
    color: #fff;
    font-size: 40px;
    font-weight: 900;
    line-height: 50px;
    padding: 25px 0 55px 0;
    text-transform: uppercase;
    width: 50%;
  }
  .advantages-container {
    display: inline-block;
    position: relative;
    width: 100%;
    &:before {
      background: rgba(235, 130, 32, 0.85);
      content: '';
      height: 6px;
      left: 3%;
      position: absolute;
      top: -6px;
      width: 94%;
    }
    &:after {
      background: rgba(235, 130, 32, 0.85);
      content: '';
      height: 6px;
      left: 3%;
      position: absolute;
      bottom: -6px;
      width: 94%;
    }
  }
  .group-advantage {
    background: #fff;
    display: inline-block;
    float: left;
    height: 300px;
    padding: 32px 35px;
    width: 50%;
    &:nth-child(2n) {
      background: #f2f6f8;
    }
    div[class*='field-name-field-advantages-title'] {
      color: #333;
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      margin: 10px 0 15px 0;
    }
    div[class*='field-name-field-advantages-desc'] {
      color: #7a7a7a;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.pane-m-library-call {
  position: relative;
  width: 100%;
  .bootstrap-twocol-stacked {
    position: relative;
    z-index: 2;
    .slider-title {
      padding: 80px 0 70px 0;
      .pane-content {
        color: #fff;
        h2 {
          font-size: 40px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
    }
    .webform-title {
      .pane-content {
        color: #fff;
        text-align: center;
        h2 {
          font-size: 28px;
          font-weight: 900;
          line-height: 34px;
          text-transform: uppercase;
        }
      }
    }
    .webform-col {
      .webform-client-form {
        div[class*='col'] {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}
.view-id-m_library_slider {
  color: #fff;
  .views-field-title {
    margin-bottom: 25px;
    a {
      color: #fff;
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      text-decoration: none;
    }
  }
  .views-field-field-r-author-reviews {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 40px;
  }
  .views-field-body {
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
  }
}
.owl-theme.owl-theme-m_library {
  .owl-item {
    padding-left: 2px;
    padding-right: 2px;
  }
  .owl-controls {
    margin-top: 40px;
    text-align: left;
    .owl-prev,
    .owl-next {
      border: 1px solid #fff;
      border-radius: 0;
      height: 40px;
      margin: 0;
      padding: 0;
      width: 40px;
    }
    .owl-prev {
      background: url(../images/slider_white_arrow_prev.png) center no-repeat;
      margin-right: 25px;
      &:hover {
        background: url(../images/certificates_slider_arrow_prev_hover.png) center no-repeat;
        border: 1px solid $yellow;
      }
    }
    .owl-next {
      background: url(../images/slider_white_arrow_next.png) center no-repeat;
      &:hover {
        background: url(../images/certificates_slider_arrow_next_hover.png) center no-repeat;
        border: 1px solid $yellow;
      }
    }
  }
}

//отзывы
.pane-reviews-slider-panel-pane-1 {
  background-color: #fff;
  padding: 70px 0 50px;
  > .pane-content {
    @extend .container;
  }
}
.view-id-reviews_slider {
  .view-content {
    text-align: center;
  }
  .views-field-field-r-photo {
    margin-bottom: 23px;
    img {
      border-radius: 50%;
    }
  }
  .views-field-title {
    color: #333;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .views-field-field-r-position {
    color: #0e6fad;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .views-field-body {
    color: #7a7a7a;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    &:before {
      background: url(../images/quote_icon_left.png) center no-repeat;
      content: '';
      height: 17px;
      left: -60px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 18px;
    }
    &:after {
      background: url(../images/quote_icon_right.png) center no-repeat;
      content: '';
      height: 17px;
      right: -60px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 18px;
    }
  }
}
.owl-theme.owl-theme-reviews {
  .owl-item {
    > div[class*='item'] {
      margin: 0 auto;
      max-width: 910px;
      padding-left: 60px;
      padding-right: 60px;
      width: 100%;
    }
  }
  .owl-controls {
    margin-top: 20px;
    text-align: center;
    .owl-prev,
    .owl-next {
      border: 1px solid $blue;
      border-radius: 0;
      height: 40px;
      margin: 0 13px;
      padding: 0;
      width: 40px;
    }
    .owl-prev {
      background: url(../images/certificates_slider_arrow_prev.png) center no-repeat;
      &:hover {
        background: url(../images/certificates_slider_arrow_prev_hover.png) center no-repeat;
        border: 1px solid $yellow;
      }
    }
    .owl-next {
      background: url(../images/certificates_slider_arrow_next.png) center no-repeat;
      &:hover {
        background: url(../images/certificates_slider_arrow_next_hover.png) center no-repeat;
        border: 1px solid $yellow;
      }
    }
  }
}


@include respond-to('small') {
  .front-top-pane {
    background-position: right -954px center;
    height: 840px;
    .container {
      padding: 0 10px;
    }
    .content-left {
      padding-top: 75px;
      width: 100%;
      .row {
        text-align: center;
      }
      .rectangle {
        background-position: right center;
        font-size: 36px;
        padding-right: 100px;
        padding-top: 53px;
        width: 462px;
      }
      .col-sm-6 {
        display: inline-block;
        float: none;
        left: 0;
        vertical-align: top;
      }
    }
  }
  .front-about-pane {
    background: #fff;
    padding: 65px 10px;
    .container {
      min-height: auto;
      padding-bottom: 330px;
      position: relative;
      &:before {
        background: url(../images/front_about_image.png) center no-repeat;
        background-size: cover;
        bottom: 0;
        content: '';
        height: 321px;
        left: 50%;
        margin-left: -150px;
        position: absolute;
        top: auto;
        width: 300px;
      }
    }
  }
  .pane-advantages {
    background: #fff;
    padding-bottom: 0;
    .field-name-field-advantages-title {
      color: $black;
      font-size: 30px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      width: 100%;
    }
    .advantages-container {
      display: block;
      &:before,
      &:after {
        display: none;
      }
    }
    .group-advantage {
      height: auto;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
    }
  }
  .pane-m-library-call {
    .slider-col {
      background: url(../images/m_library_front_bg.jpg) center no-repeat;
      background-size: cover;
      padding: 80px 0;
      .panel-pane {
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }
    }
    .webform-col {
      .panel-pane {
        background-color: rgba(245,130,32,.82);
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }
    }
    .webform-title {
      padding-top: 50px;
      h2 {
        margin: 0;
      }
    }
    .pane-webform {
      padding-bottom: 50px;
    }
  }
  .view-id-reviews_slider {
    .views-field-body {
      &:before {
        left: -25px;
      }
      &:after {
        right: -25px;
      }
    }
  }
  .owl-theme.owl-theme-reviews {
    .owl-item {
      > div[class*='item'] {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

@include respond-to('medium') {
  .front-top-pane {
    .container {
      padding: 0 20px;
    }
    .content-left {
      .rectangle {
        background-position: right center;
        font-size: 36px;
        padding-right: 100px;
        padding-top: 53px;
        width: 462px;
      }
    }
  }
  .front-about-pane {
    background: #fff;
    padding: 100px 0;
    .container {
      min-height: auto;
      padding-bottom: 670px;
      position: relative;
      &:before {
        background: url(../images/front_about_image.png) center no-repeat;
        bottom: 0;
        content: '';
        height: 644px;
        left: 16.66667%;
        position: absolute;
        top: auto;
        width: 603px;
      }
      .rectangle.rect-white-bg {
        //background: url(../images/rectangle_services_mobile.png) right 34px center no-repeat;
        background-image: url(../images/front_about_rectangle.png);
      }
    }
  }
  .pane-advantages {
    .field-name-field-advantages-title {
      font-size: 36px;
    }
    .group-advantage {
      height: 317px;
      padding-left: 20px;
      padding-right: 20px;
      div[class*='field-name-field-advantages-title'] {
        font-size: 23px;
      }
    }
  }
  .pane-m-library-call {
    .slider-col {
      background: url(../images/m_library_front_bg.jpg) center no-repeat;
      background-size: cover;
      padding: 140px 0 90px 0;
      .panel-pane {
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 512px;
      }
    }
    .webform-col {
      background: url(../images/m_library_front_right_bg.jpg) center no-repeat;
      background-size: cover;
      padding: 140px 0 90px 0;
      .panel-pane {
        background-color: rgba(245,130,32,.82);
        margin: 0 auto;
        padding-left: 60px;
        padding-right: 60px;
        width: 512px;
      }
    }
    .webform-title {
      padding-top: 100px;
      h2 {
        margin: 0;
      }
    }
    .pane-webform {
      padding-bottom: 80px;
    }
  }
  .view-id-reviews_slider {
    .views-field-body {
      &:before {
        left: -25px;
      }
      &:after {
        right: -25px;
      }
    }
  }
  .owl-theme.owl-theme-reviews {
    .owl-item {
      > div[class*='item'] {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

@include respond-to('large') {
  .pane-m-library-call {
    &:before {
      background: url(../images/m_library_front_bg.jpg) center no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
    }
    &:after {
      background: url(../images/m_library_front_right_bg.jpg) center no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      right: 0;
      position: absolute;
      top: 0;
      width: 50%;
    }
    > .pane-content {
      margin: 0 auto;
      width: 1024px;
    }
    .bootstrap-twocol-stacked {
      padding: 133px 0 92px 0;
      .webform-col {
        background-color: rgba(245,130,32,.82);
        padding: 80px 60px;
      }
    }
  }
  .view-id-m_library_slider {
    .view-content {
      padding-right: 60px;
    }
  }
}


#gmap_canvas{
  height: 580px;
}
