// Page Layout
// -----------------------------------------------------------------------------
/**
$yellow:  #f58220;
$black:   #333333;
$grey:    #7a7a7a;
$blue:    #0e6fad;
$white:   #ffffff;
**/

.yellow{
  color:  $yellow;
}
.black{
  color: $black;
}
.grey{
  color: $grey;
}
.blue{
  color: $blue;
}
.white{
  color: $white;
}

.bg-yellow{
  background-color: $yellow;
}
.bg-black{
  background-color: $black;
}
.bg-grey{
  background-color: $grey;
}
.bg-blue{
  background-color: $blue;
}
.bg-white{
  background-color: $white;
}

body {
  font-weight: 300;
}
#page-wrapper {
  background-color: #fafafc;
  overflow: hidden;
  width: 100%;
}
#page-header {
  border: none;
}
h1.title {
  color: #333;
  font-size: 28px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
.ajax-progress {
  display: none;
}
.desktop-only {
  display: none;
}
#main {
  color: $grey;
}
.pane-header-row {
  .rectangle {
    background: url(../images/rectangle_bg.png) right 50px center no-repeat;
    font-size: 55px;
    height: 207px;
    line-height: 67px;
    padding-top: 40px;
    font-weight: bold;
    &.rect-white-bg {
      background-image: url(../images/front_about_rectangle.png);
    }
  }
}

.formatter-text {
  h2 {
    font-size: 24px;
    font-weight: 300;
  }
  .number {
    background-color: $blue;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 900;
    height: 27px;
    line-height: 27px;
    text-align: center;
    vertical-align: middle;
    width: 27px;
  }
  blockquote {
    background: #fafafc url(../images/quote_icon.png) left center no-repeat;
    border: none;
    padding: 30px 0 30px 45px;
    position: relative;
    &:before {
      background: #fafafc;
      content: '';
      height: 100%;
      left: -25px;
      position: absolute;
      top: 0;
      width: 25px;
    }
    &:after {
      background: #fafafc;
      content: '';
      height: 100%;
      position: absolute;
      right: -25px;
      top: 0;
      width: 25px;
    }
    > * {
      background-color: #eef4f7;
      border-left: 2px solid #f58220;
      display: block;
      padding: 10px 20px;
    }
  }
  ul {
    padding-left: 0px;
    > li {
      padding-left: 0px;
      list-style-image: url(../images/list_style_icon1.png);
      margin-bottom: 5px;
      list-style-position: inside;
    }
    ul {
      padding-bottom: 5px;
      padding-left: 14px;
      padding-top: 10px;
      li {
        list-style-image: url(../images/list_style_icon2.png);
        margin-bottom: 5px;
      }
    }
  }
}

.field-name-field-page-top-block {
  @extend .clearfix;
  position: relative;
  .field-name-field-left-image-bg,
  .field-name-field-right-image-bg {
    display: inline-block;
    float: left;
    height: 750px;
    width: 50%;
    img {
      object-fit: cover;
    }
  }
  .field-name-field-left-text {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    .field-items {
      @extend .container;
      height: 750px;
      .field-item {
        color: #fff;
        display: table-cell;
        font-size: 15px;
        height: 750px;
        padding-right: 50px;
        vertical-align: middle;
        width: 512px;
        .rectangle {
          background: url(../images/rectangle_bg.png) right center no-repeat;
          font-size: 36px;
          font-weight: 900;
          height: 207px;
          line-height: 43px;
          margin-bottom: 25px;
          padding-top: 60px;
        }
        .yellow-btn {
          background: $yellow;
          color: #fff;
          display: inline-block;
          font-size: 16px;
          font-weight: 300;
          height: 30px;
          line-height: 30px;
          margin-top: 25px;
          padding: 0 40px;
          text-decoration: none;
        }
      }
    }
  }
}

//pagination
ul.pagination.pager {
  li {
    border: none;
    margin: 5px;
    a {
      background: none;
      border: none;
      line-height: 30px;
      padding: 0;
      text-align: center;
    }
    &.pager-current {
      span {
        background: $yellow;
        border: none;
        display: block;
        font-weight: 300;
        height: 30px;
        line-height: 30px;
        padding: 0;
        width: 30px;
      }
    }
    &.pager-item {
      a {
        background: #fff;
        color: #363636;
        font-size: 15px;
        font-weight: 300;
        height: 30px;
        width: 30px;
        &:hover {
          background: $yellow;
          color: #fff;
        }
      }
    }
    &.pager-previous,
    &.pager-next,
    &.pager-first,
    &.pager-last {
      a {
        color: #363636;
        font-size: 15px;
        font-weight: 300;
        &:hover {
          color: $yellow;
          text-decoration: underline;
        }
      }
    }
    &.pager-first,
    &.pager-last {
      /*display: none;*/
    }
    &.pager-ellipsis {
      display: none;
    }
  }
}
.pane-custom {
  .pane-content {
    color: $grey;
    font-size: 14px;
    h2 {
      font-size: 24px;
      font-weight: 300;
    }
    .number {
      background-color: $blue;
      color: #fff;
      display: inline-block;
      font-size: 18px;
      font-weight: 900;
      height: 27px;
      line-height: 27px;
      text-align: center;
      vertical-align: middle;
      width: 27px;
    }
    blockquote {
      background: #fafafc url(../images/quote_icon.png) left center no-repeat;
      border: none;
      padding: 30px 0 30px 45px;
      position: relative;
      color: #7a7a7a;
      font-family: Arial;
      font-size: 18px;
      font-style: italic;
      line-height: 22px;
      &:before {
        background: #fafafc;
        content: '';
        height: 100%;
        left: -25px;
        position: absolute;
        top: 0;
        width: 25px;
      }
      &:after {
        background: #fafafc;
        content: '';
        height: 100%;
        position: absolute;
        right: -25px;
        top: 0;
        width: 25px;
      }
      > * {
        background-color: #eef4f7;
        border-left: 2px solid #f58220;
        display: block;
        padding: 10px 20px;
      }
    }
    ul {
      padding-left: 20px;
      > li {
        list-style-image: url(../images/list_style_icon1.png);
        margin-bottom: 5px;
        padding-left: 15px;
      }
      ul {
        padding-bottom: 5px;
        padding-top: 10px;
        li {
          list-style-image: url(../images/list_style_icon2.png);
          margin-bottom: 5px;
        }
      }
    }
  }
}

//colorbox
.cboxPhoto,
#cboxContent {
  border-radius: 0;
}
#colorbox, #cboxOverlay, #cboxWrapper, #cboxContent {
  overflow: visible;
}
#cboxLoadedContent {
  img {
    border: 10px solid #fff;
  }
}
#cboxClose.cbox-close-plain {
  background: url(../images/colorbox_close.jpg) center no-repeat;
  border-radius: 0;
  font-size: 0;
  height: 25px;
  opacity: 1 !important;
  right: 0;
  text-indent: -99999px;
  top: 0;
  width: 25px;
  &:hover {
    background: url(../images/colorbox_close_hover.jpg) center no-repeat;
  }
}
#cboxPrevious {
  background: url(../images/colorbox_arrow_prev.png) center no-repeat;
  clip: auto;
  cursor: pointer;
  font-size: 0;
  height: 40px;
  left: -100px;
  text-indent: -99999px;
  top: 50%;
  width: 40px;
  &:hover {
    background: url(../images/colorbox_arrow_prev_hover.png) center no-repeat;
  }
  &:focus {
    outline: none;
  }
}
#cboxNext {
  background: url(../images/colorbox_arrow_next.png) center no-repeat;
  clip: auto;
  cursor: pointer;
  font-size: 0;
  height: 40px;
  right: -100px;
  text-indent: -99999px;
  top: 50%;
  width: 40px;
  &:hover {
    background: url(../images/colorbox_arrow_next_hover.png) center no-repeat;
  }
  &:focus {
    outline: none;
  }
}

//wiews filter checkbox
.bef-select-as-checkboxes {
  letter-spacing: -99px;
  text-align: center;
  .form-item {
    display: inline-block;
    letter-spacing: normal;
    margin: 0;
    input[type=checkbox] {
      display: none;
      &:checked {
        + label {
          background: $blue url(../images/checkbox_checked_icon.png) left 20px top 9px no-repeat;
          color: #fff;
        }
      }
    }
    label {
      background: #f2f6f8 url(../images/checkbox_icon.png) left 20px center no-repeat;
      border: 1px solid #e1e1e1;
      color: $grey;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
      display: inline-block;
      line-height: 40px;
      margin: 0;
      height: 40px;
      text-align: left;
      padding: 0 29px 0 49px;
      position: relative;
      &:hover {
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        color: $yellow;
        text-decoration: underline;
        z-index: 2;
      }
    }
  }
}

.pane-webform {
  padding: 40px 0;
  .alert {
    display: none;
  }
  .pane-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    padding-bottom: 20px;
    text-align: center;
  }
  select,
  textarea,
  input[type=text],
  input[type=email] {
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $black;
    font-size: 15px;
    font-weight: 300;
    height: 40px;
    &.error {
      border: 2px solid red;
    }
    &::-webkit-input-placeholder {color: $black;}
    &::-moz-placeholder          {color: $black;}/* Firefox 19+ */
    &:-moz-placeholder           {color: $black;}/* Firefox 18- */
    &:-ms-input-placeholder      {color: $black;}
  }
  select {
    appearance: none !important;
    background-image: url(../images/select_arrow.png);
    background-position: right 15px center;
    background-repeat: no-repeat;
    padding-right: 50px;
  }
  textarea {
    height: 111px;
    resize: none;
  }
  input[type=submit] {
    border-radius: 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    height: 40px;
    min-width: 210px;
    max-width: 100%;
    padding: 0 30px;
    text-align: center;
  }
  .form-item {
    margin: 7px 0;
  }
  .form-actions {
    display: inline-block;
    margin: 38px 0;
    text-align: center;
    width: 100%;
  }
  &.bg-white {
    .pane-title {
      color: $black;
    }
    select,
    textarea,
    input[type=text],
    input[type=email] {
      background-color: #f2f6f8;
    }
    input[type=submit] {
      background: $blue;
      color: #fff;
    }
  }
  &.bg-yellow {
    .pane-title {
      color: #fff;
    }
    select,
    textarea,
    input[type=text],
    input[type=email] {
      background-color: rgba(255,255,255,0.8);
      &:focus {
        background-color: #fff;
      }
    }
    input[type=submit] {
      background: $blue;
      color: #fff;
    }
  }
  &.pane-webform-client-block-160 {
    input[type=submit] {
      max-width: 210px;
      padding: 0;
      width: 100%;
    }
    .form-actions {
      display: inline-block;
      float: left;
      margin: 14px 0;
      width: 218px;
    }
    .form-item {
      margin: 14px 0;
      &.webform-component--imya {
        display: inline-block;
        float: left;
        padding-right: 20px;
        width: 403px;
      }
      &.webform-component--nomer-telefona {
        display: inline-block;
        float: left;
        padding-right: 20px;
        width: 403px;
      }
    }
  }
}

#modalContent {
  @extend .pane-webform;
  input[type=text],
  input[type=email],
  select,
  textarea {
    background-color: #f2f6f8;
  }
  div.uworld-ctools-modal-content {
    @extend .container;
    border: none;
  }
  .uworld-popup-header {
    background: none;
    border: none;
    position: relative;
    padding-top: 60px;
    padding-bottom: 20px;
    .uworld-popup-title {
      color: #333;
      display: inline-block;
      font-size: 24px;
      font-weight: 300;
      line-height: normal;
      text-align: center;
      white-space: normal;
      width: 100%;
    }
    .close {
      background: url(../images/modal_close_icon.png) center no-repeat;
      cursor: pointer;
      color: #fff;
      display: block;
      height: 20px;
      opacity: 1;
      outline: none;
      position: absolute;
      right: 10px;
      text-indent: -99999px;
      top: 10px;
      width: 20px;
    }
  }
  .uworld-popup-content {
    border: none;
    padding: 0 50px 20px 50px;
  }
}

.pane-team {
  padding: 75px 0 55px 0;
  .pane-custom {
    .pane-content {
      margin: 0 auto;
      max-width: 840px;
      padding-bottom: 40px;
      h2 {
        font-size: 30px;
        font-weight: 900;
      }
    }
  }
}


//responsive grid
.views-responsive-grid-vertical {
  &.views-columns-2 {
    width: 100%;
    display: inline-block;
    .views-column {
      display: inline-block;
      float: left;
      width: 50%;
      &.views-column-1 {
        padding-right: 19px;
      }
      &.views-column-2 {
        padding-left: 19px;
      }
    }
  }
}

//как мы работаем
.pane-how-we-are-working-material,
.pane-how-we-are-working {
  background: url(../images/working_bg.jpg) center no-repeat;
  background-size: cover;
  padding-bottom: 90px;
  > .pane-content {
    @extend .container;
  }
  .pane-1 {
    padding: 70px 0 50px 0;
    .pane-content {
      font-size: 40px;
      font-weight: 900;
      line-height: 48px;
    }
  }
}
.working-slider-pagination {
  padding: 40px 0 25px 0;
  .field-name-field-working-title {
    color: #333;
    cursor: pointer;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    padding-left: 75px;
    position: relative;
    &:before {
      border: 3px solid #0e6fad;
      border-radius: 2px;
      content: '';
      height: 16px;
      left: 35px;
      opacity: 0.25;
      position: absolute;
      top: 12px;
      width: 16px;
    }
    &.active {
      background: $yellow;
      color: #fff;
      &:before {
        opacity: 0.75;
        border: 3px solid #fff;
      }
    }
  }
}
&.working-slider {
  background: #fff;
  float: left;
  width: 100%;
  .field-name-field-working-title {
    color: $black;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
  }
  .field-name-field-working-desc {
    font-size: 16px;
    ul {
      padding-left: 18px;
      > li {
        list-style-image: url(../images/list_style_icon1.png);
        margin-bottom: 5px;
      }
      ul {
        padding-bottom: 5px;
        padding-top: 10px;
        li {
          list-style-image: url(../images/list_style_icon2.png);
          margin-bottom: 5px;
        }
      }
    }
  }
}
.owl-theme.owl-theme-working {
  .owl-item {
    padding: 50px 100px;
  }
  /*
  .owl-controls {
    .owl-prev,
    .owl-next {
      height: 30px;
      position: absolute;
      bottom: 10px;
      width: 26px;
    }
    .owl-prev {
      background: url(../images/certificates_slider_arrow_prev.png) center no-repeat;
      margin-right: 200px;
      right: 50%;
      &:hover {
        background: url(../images/certificates_slider_arrow_prev_hover.png) center no-repeat;
      }
    }
    .owl-next {
      background: url(../images/certificates_slider_arrow_next.png) center no-repeat;
      left: 50%;
      margin-left: 200px;
      &:hover {
        background: url(../images/certificates_slider_arrow_next_hover.png) center no-repeat;
      }
    }
  }
  */
  .owl-controls {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    .owl-prev,
    .owl-next {
      border: 1px solid $blue;
      border-radius: 0;
      height: 40px;
      margin: 0 13px;
      padding: 0;
      width: 40px;
    }
    .owl-prev {
      background: url(../images/certificates_slider_arrow_prev.png) center no-repeat;
      &:hover {
        background: url(../images/certificates_slider_arrow_prev_hover.png) center no-repeat;
        border: 1px solid $yellow;
      }
    }
    .owl-next {
      background: url(../images/certificates_slider_arrow_next.png) center no-repeat;
      &:hover {
        background: url(../images/certificates_slider_arrow_next_hover.png) center no-repeat;
        border: 1px solid $yellow;
      }
    }
  }
  .owl-pagination {
    display: none;
  }
}

.bef-checkboxes {
  align-content: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  .form-item {
    flex-grow: 2;
    label {
      background: #f2f6f8 url(../images/checkbox_icon.png) left 12px center no-repeat;
      padding: 0 19px 0 39px;
      width: 100%;
    }
    input[type=checkbox]:checked + label {
      background: #0e6fad url(../images/checkbox_checked_icon.png) left 12px top 9px no-repeat;
      color: #fff;
    }
  }
}


@include respond-to('small') {
  #modalContent {
    left: 0 !important;
  }
  .field-name-field-page-top-block {
    position: relative;
    .field-name-field-left-image-bg {
      height: 510px;
      width: 100%;
    }
    .field-name-field-right-image-bg {
      width: 100%;
      height: auto;
      img {
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }
    .field-name-field-left-text {
      height: 510px;
      .field-items {
        height: 510px;
        .field-item {
          height: 510px;
          padding: 0 10px;
          width: 100%;
          .rectangle {
            //background: url(../images/rectangle_mobile_bg.png) right center no-repeat;
            background: none;
            font-size: 25px;
            height: 152px;
            line-height: 30px;
            padding-top: 33px;
          }
        }
      }
    }
  }
  .pane-header-row {
    .rectangle {
      background: url(../images/rectangle_mobile_bg.png) right 34px center no-repeat;
      font-size: 36px;
      height: 152px;
      line-height: 44px;
      padding-top: 33px;
      &.rect-white-bg {
        background-image: url(../images/front_about_rectangle_mobile.png);
        font-size: 36px;
        height: 152px;
        line-height: 44px;
        padding-top: 33px;
      }
    }
  }
  .pane-webform {
    .container {
      padding-left: 10px;
      padding-right: 10px;
    }
    &.pane-webform-client-block-160 {
      .form-item {
        &.webform-component--imya,
        &.webform-component--nomer-telefona {
          width: 100%;
        }
      }
      .form-actions {
        margin: 7px 0;
        text-align: center;
        width: 100%;
      }
    }
  }

  .pane-how-we-are-working,
  .pane-how-we-are-working-material {
    background: #fff;
    padding-bottom: 0;
    .pane-1 {
      padding: 50px 0 0 0;
      .pane-content {
        line-height: normal;
        font-size: 30px;
        text-align: center;
        .white {
          color: #333;
        }
      }
    }
  }
  .owl-theme.owl-theme-working {
    .owl-item {
      padding: 30px 10px;
    }
    .owl-controls {
      .owl-prev {
        left: 0;
        margin: 0;
        right: auto;
        top: 30px;
      }
      .owl-next {
        left: auto;
        margin: 0;
        right: 0;
        top: 30px;
      }
    }
  }
  .working-slider-pagination {
    display: none;
  }
  .working-slider {
    .field-name-field-working-title {
      font-size: 24px;
    }
  }
  .views-responsive-grid-vertical {
    &.views-columns-2 {
      .views-column {
        width: 100%;
        &.views-column-1 {
          padding-right: 0;
        }
        &.views-column-2 {
          padding-left: 0;
        }
      }
    }
  }
  #modalContent {
    .uworld-popup-header {
      .close {
        right: 0;
      }
    }
    .uworld-popup-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@include respond-to('medium') {
  .field-name-field-page-top-block {
    position: relative;
    .field-name-field-left-image-bg {
      height: 360px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .field-name-field-right-image-bg {
      width: 100%;
      height: auto;
      img {
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }
    .field-name-field-left-text {
      height: 360px;
      .field-items {
        height: 360px;
        .field-item {
          height: 360px;
          width: 100%;
          .rectangle {
            background: url(../images/rectangle_mobile_bg.png) right 100px center no-repeat;
            font-size: 36px;
            height: 152px;
            line-height: 44px;
            padding-top: 33px;
          }
        }
      }
    }
  }
  .pane-header-row {
    .rectangle {
      background: url(../images/rectangle_mobile_bg.png) right 34px center no-repeat;
      font-size: 36px;
      height: 152px;
      line-height: 44px;
      padding-top: 33px;
      &.rect-white-bg {
        background-image: url(../images/rectangle_mobile_bg.png);
        font-size: 55px;
        height: 207px;
        line-height: 67px;
        padding-top: 40px;
      }
    }
  }
  .pane-webform {
    .form-item {
      &.col-md-6 {
        &:nth-child(2n+1) {
          padding-right: 20px;
        }
        &:nth-child(2n) {
          padding-left: 20px;
        }
      }
    }
    input[type=submit] {
      width: auto;
    }
    &.pane-webform-client-block-160 {
      .form-item {
        &.webform-component--imya,
        &.webform-component--nomer-telefona {
          width: 259px;
        }
      }
      .form-actions {
        width: 210px;
      }
    }
  }
  #modalContent {
    .uworld-popup-content {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@include respond-to('large') {
  .desktop-only {
    display: block;
  }
  .pane-webform {
    .form-item {
      &.col-lg-6 {
        &:nth-child(2n+1) {
          padding-right: 20px;
        }
        &:nth-child(2n) {
          padding-left: 20px;
        }
      }
    }
  }
  .front-about-pane {
    .pane-content {
      ul {
        width: 115%;
      }
    }
  }
}
