#footer {
  background: url(../images/footer_bg.jpg) center no-repeat;
  background-size: cover;
  padding: 70px 0 30px 0;
  text-align: center;
  .footer-logo {
    margin-bottom: 25px;
  }
  .block__content {
    color: #fff;
  }
}