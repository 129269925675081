body.page-reviews{

	#panel-bootstrap-region-center{

	}
	#panel-bootstrap-region-bottom_side{
		margin: 39px 0 0px 0;
		padding: 0 0 27px 0;
		background-color: #fff;
		.pane-webform{
			padding: 0;
			.form-control{
				background-color: #f2f6f8;
			}
			h2.pane-title{
				margin-top: 64px;
			}
		}
	}
	.pane-custom.page-title{
		text-align: center;
		text-transform: uppercase;
		h2{
			font-family: "HelveticaNeueCyr";
			font-style: normal;
			font-weight: 900;
			color: #333333;
			font-size: 28px;
			margin: 75px 0 16px 0;
			span{
				color: #0e6fad;
			}
		}
		.after-title{
			font-family: "HelveticaNeueCyr";
			font-style: normal;
			font-weight: 300;
			color: #333333;
			font-size: 15px;
			padding: 0 95px;
			line-height: 23px;
		}
	}

}

.view-v-otzyvy{
	margin: 47px 0 0 0;
	.view-content{
		font-size: 0;
		.views-row{
			width: 50%;
			display: inline-table;
			height: 410px;
			padding:33px 25px 0 33px;
			&:nth-child(3n+1){
				background-color: #fff;
			}
			&:nth-child(3n+2){
				background-color: #f2f6f8;
			}
			&:nth-child(3n+3){
				background-color: #fafafc;
			}
			.views-field-field-r-photo{
				float: left;
				margin: 0 25px 0 0;
			}
			.views-field-title{
				font-family: "HelveticaNeueCyr";
				font-style: normal;
				font-weight: 300;
				font-size: 24px;
				color: #333333;
				margin: 13px 0 0 0;
			}
			.views-field-field-r-position{
				font-family: "HelveticaNeueCyr";
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				color: #0e6fad;
				margin: 3px 0 0 0;
			}
			.views-field-body{
				font-family: "HelveticaNeueCyr";
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				color: #7a7a7a;
				clear: both;
				padding: 38px 0 0 0;
				line-height: 21px;
			}

		}

	}
	.item-list{
		ul.pagination{
			margin: 57px 0 0 0;
		}
	}
}

#webform-client-form-226 {
	.form-radios {
		text-align: center;
		.form-type-radio {
			display: inline-block;
			line-height: 23px;
			padding: 0 20px;
		}
	}
}

@include respond-to('small') {
	#webform-client-form-226 {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@include respond-to('medium') {
	#webform-client-form-226 {
		padding-left: 20px;
		padding-right: 20px;
		.webform-component--fio {
			padding-right: 16px;
		}
		.webform-component--adres-sayta {
			padding-left: 8px;
			padding-right: 8px;
		}
		.webform-component--e-mail {
			padding-left: 16px;
		}
	}
}
@include respond-to('large') {
	#webform-client-form-226 {
		.webform-component--fio {
			padding-right: 16px;
		}
		.webform-component--adres-sayta {
			padding-left: 8px;
			padding-right: 8px;
		}
		.webform-component--e-mail {
			padding-left: 16px;
		}
	}
}
