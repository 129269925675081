// Mixins
// -----------------------------------------------------------------------------
@mixin make-container() {
  @include container-fixed();

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (min-width: $screen-sm) and (max-width: $screen-md - 1 )  {
      @content;
    }
  }

  @else if $breakpoint == "medium" {
    @media (min-width: $screen-md) and (max-width: $screen-lg - 1 ) {
      @content;
    }
  }

  @else if $breakpoint == "large" {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
  @else if $breakpoint == "medium_small" {
    @media (max-width: $screen-lg - 1 ) {
      @content;
    }
  }
}
